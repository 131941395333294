import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'rosie-nav-sidebar-section',
  templateUrl: './nav-sidebar-section.component.html',
  styleUrls: ['./nav-sidebar-section.component.scss'],
  animations: [
    trigger('slideIn', [
      transition('false => true', animate('200ms linear', keyframes([
        style({ transform: 'translateX(30%)' }),
        style({ transform: 'translateX(0)' })
      ])))
    ]),
  ]
})
export class NavSidebarSectionComponent implements OnInit, OnChanges {
  @Input() navUrl: string;
  @Input() navBreadcrumbs: SidebarSectionBreadcrumb[];
  @Input() type: SidebarSectionType;
  @Input() customContentClass = '';
  @Output() navClicked = new EventEmitter<boolean>();

  triggerAnimation = false;

  @HostBinding('class') hostClass = 'nav-sidebar-section';

  constructor() {
  }

  get breadcrumbs(): SidebarSectionBreadcrumb[] {
    return this.navBreadcrumbs;
  }

  get hasBreadcrumbs(): boolean {
    return this.breadcrumbs?.length > 1;
  }

  get tagName(): string {
    return `SIDEBAR.SECTION.HEADING.TAG.${this.type.toUpperCase()}`;
  }

  get title(): string {
    return this.breadcrumbs?.[0].label;
  }

  get navIcon(): string {
    return this.hasBreadcrumbs ? 'chevron' : 'close-icon';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.navBreadcrumbs?.firstChange && changes.navBreadcrumbs?.currentValue?.length !== changes.navBreadcrumbs?.previousValue?.length) {
      this.triggerAnimation = this.hasBreadcrumbs;
    }
  }

  trackByLabel(_index: number, item: SidebarSectionBreadcrumb) {
    return item.label;
  }

}

export enum SidebarSectionType {
  Call = 'call',
  Offer = 'offer',
  CheckoutLink = 'checkout_link',
  OrderBump = 'order_bump'
}

export interface SidebarSectionBreadcrumb {
  label: string;
  iconClass?: string;
}
