<h5 class="modal-title">{{ getAppStatusTranslation('HEADING', true) }}</h5>
<rosie-alert *ngIf="app?.isPending" [message]="getAppStatusTranslation('WARNING')" [type]="'warning'" class="my-3">
</rosie-alert>
<div [ngClass]="{ 'mb-4': showPostInstallFields }" class="modal-description dark">
  {{
    showPostInstallFields ? getAppStatusTranslation('DESCRIPTION_WITH_SETTINGS', true) :
      getAppStatusTranslation('DESCRIPTION', true)
  }}
</div>

<div *ngIf="showPostInstallFields" class="settings-form">
  <div class="app-section-heading-wrapper mb-4">
    <div class="app-section-heading">{{ getAppStatusTranslation('SETTINGS_HEADING', true) }}</div>
  </div>
  <ng-container
    [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
    [ngTemplateOutlet]="form">
  </ng-container>
</div>

<rosie-button (click)="modal.closeAll()" *ngIf="!app?.hasPostInstallFields" [fullWidth]="true"
              [translationKey]="'APP.STATUS.OK'"
              class="w-100">
</rosie-button>
<div *ngIf="showPostInstallFields" class="d-flex">
  <rosie-button (click)="modal.closeAll()" [customClass]="'btn-secondary btn-block'"
                [translationKey]="getAppStatusTranslation('SETTINGS_SKIP', true)"
                class="d-block w-100 mr-2">
  </rosie-button>
  <rosie-button (click)="updateModalActionConfig.actionFunc()" [translationKey]="updateModalActionConfig.translationKey"
                class="d-block w-100">
  </rosie-button>
</div>
