import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderConfig } from '../loader/loader.component';

@Component({
  selector: 'rosie-list',
  template: `
    <div class="rosie-list position-relative" [ngClass]="customClass">
      <div class="rosie-list-header col d-none d-md-block">
        <div class="row p-0 m-0">
          <div *ngFor="let header of headers" (click)="sortList(header.sortParam)" [ngClass]="header.cssClass"
               class="rosie-list-header-item letter-spacing-04 text-uppercase mb-4 pl-0 pr-0" [class]="header.tooltip ? 'has-tooltip' : ''">
            {{ header.label | translate }}
            <div *ngIf="header.icon" [ngStyle]="{'background-image': 'url(assets/images/' + header.icon + ')'}"
                 [ngbTooltip]="header.tooltip ? (header.tooltip | translate) : ''" placement="right" class="rosie-list-header-icon ml-1">
            </div>
          </div>
        </div>
      </div>
      <div class="rosie-list-rows-wrapper col" infiniteScroll (scrolled)="loadMore.emit(true)" [infiniteScrollDisabled]="!hasMoreItems"
           [infiniteScrollThrottle]="10" [scrollWindow]="true"
           [infiniteScrollContainer]="infiniteScrollContainerClass" [fromRoot]="true">
        <div *ngFor="let row of rows | async" class="row rosie-list-row">
          <ng-container *ngTemplateOutlet="template; context: {row: row}"></ng-container>
        </div>
        <div *ngIf="emptyListText && (rows | async)?.length === 0"
             class="row rosie-list-row justify-content-center py-5 text-uppercase letter-spacing-04 rosie-list-message">
          <p>{{ emptyListText | translate }}</p>
        </div>
        <div *ngIf="endOfListText && !hasMoreItems && (rows | async)?.length !== 0"
             class="row rosie-list-row justify-content-center py-5 text-uppercase letter-spacing-04 rosie-list-message">
          <p>{{ endOfListText | translate }}</p>
        </div>
      </div>
      <rosie-loader [config]="loaderConfig"></rosie-loader>
    </div>
  `,
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  @Input() hasMoreItems = true;
  @Input() headers: ListHeader[];
  @Input() rows: Observable<any[]>;
  @Input() emptyListText: string;
  @Input() endOfListText: string;
  @Input() customClass = '';
  @Input() inProgress = false;
  @Input() infiniteScrollContainerClass = '';
  @Output() loadMore = new EventEmitter<boolean>();
  @Output() sort = new EventEmitter<SortParam>();

  @ContentChild('row', { static: false }) template: TemplateRef<any>;

  constructor() {
  }

  get loaderConfig(): LoaderConfig {
    return {
      show: this.hasMoreItems && this.inProgress,
      size: 'sm',
      full: false,
      icon: false,
      loaderText: 'COMMON.LOADING',
      customLoaderWrapperClass: 'overlap-loader'
    };
  }

  sortList(sortParam: SortParam) {
    if (sortParam) {
      this.sort.next(sortParam);
    }
  }

}

export interface ListHeader {
  label: string;
  cssClass: string;
  sortParam?: SortParam;
  icon?: string;
  tooltip?: string;
}

export enum SortType {
  NameAsc = 'name:asc',
  NameDesc = 'name:desc',
  IdAsc = 'id:asc',
  IdDesc = 'id:desc',
  UpdatedAtAsc = 'updated_at:asc',
  UpdatedAtDesc = 'updated_at:desc',
  PriorityAsc = 'priority:asc',
  PrioritytDesc = 'priority:desc',
  StatusAsc = 'status:asc',
  StatusDesc = 'status:desc'
}

export enum SortParam {
  Name = 'name',
  Id = 'id',
  UpdatedAt = 'updated_at',
  Priority = 'priority',
  Status = 'status'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SearchKey {
  Name = 'name'
}

export interface SearchItem {
  key: SearchKey;
  term: string;
}
