import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getApiUrl } from '../helpers/rest-utils';
import { HttpClient } from '@angular/common/http';
import { concatMap, map, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { Subscription, SubscriptionPrice, SubscriptionType } from './subscription';
import { AgentService } from '../agent/agent.service';
import { InternalAnalyticsService } from '../internal-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  type: SubscriptionPage = SubscriptionPage.Main;
  paymentMethodErrors = ['card-number', 'card-expiry', 'card-cvc'];
  initialize: Subject<SubscriptionPage> = new Subject();
  reset: Subject<SubscriptionPage> = new Subject();
  created: Subject<boolean> = new Subject();
  credentials: { publishableKey?: string, clientSecret?: string } = {};
  plans: any;

  constructor(private http: HttpClient, private user: UserService, private agent: AgentService, private analytics: InternalAnalyticsService) {

  }

  getBilling(): Observable<{ publishableKey: string, secret: string }> {
    return this.http.get(`${getApiUrl()}billing`)
      .pipe(map((res: any) => res?.data), tap((credentials) => this.credentials = credentials));
  }

  getPlans(): Observable<{ publishableKey: string, secret: string }> {
    return this.http.get(`${getApiUrl()}subscription-plans`)
      .pipe(map((res: any) => res?.data), tap((plans) => this.plans = plans));
  }


  create(paymentMethod: string, prices: SubscriptionPrice[], couponCode?: string): Observable<any> {
    const meteredPrice = prices.find(price => price.usageType === SubscriptionType.Metered);
    const licensedPrice = prices.find(price => price.usageType === SubscriptionType.Licensed);
    const data: any = { paymentMethod };
    data.prices = [{ priceId: meteredPrice.id, type: SubscriptionType.Metered }, { priceId: licensedPrice.id, type: SubscriptionType.Licensed }];
    if (couponCode) { data.couponCode = couponCode; }

    return this.http.post(`${getApiUrl()}subscriptions`, data, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res?.data), tap((subscription: Subscription) => {
        const user = this.user.userData;
        subscription.planName = this.plans?.[0].name;
        user.subscription = subscription;
        this.user.refreshUser(user);
      }), concatMap(() => this.agent.getAgent()));
  }
}

export enum SubscriptionPage {
  Account = 'account',
  Main = 'main'
}