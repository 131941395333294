<button [class]="customClass ? customClass : 'btn-primary'" [dataHook]="translationKey" [disabled]="isDisabled"
  [ngClass]="{'w-100': fullWidth, 'disabled': isDisabled}" [type]="customType" class="btn">
  <div class="d-flex align-items-center flex-nowrap justify-content-center">
    <ng-container>
      <ng-content></ng-content>
      <rosie-loader *ngIf="inProgress"
        [config]="{show: inProgress, customLoaderWrapperClass: darkLoader ? 'rosie-dark-loader' : 'rosie-btn-loader'}">
      </rosie-loader>
      <img *ngIf="!inProgress && iconPrefix" [src]="iconPrefix" class="mr-2">
      <span *ngIf="!inProgress" [ngClass]="{'rosie-invisible': inProgress}">{{ translationKey | translate }}</span>
      <img *ngIf="!inProgress && iconSuffix" [src]="iconSuffix" class="ml-2">
      <ng-icon *ngIf="!inProgress && iconSuffixConfig?.name" [name]="iconSuffixConfig?.name"
        [color]="isDisabled ? iconSuffixConfig?.disabledStateColor : iconSuffixConfig.color" class="ml-2"></ng-icon>
    </ng-container>
  </div>
</button>