<div class="burger-menu" *ngIf="isMobileVersion" (click)="toggleMenu()" [ngClass]="{'expanded': isExpanded}"><img
    [src]="isExpanded ? 'assets/images/close.svg' : 'assets/images/burger.svg'"></div>
<div class="d-flex flex-column justify-content-start align-items-center nav-sidebar"
  [ngClass]="{'hideable': isMobileVersion, 'expanded': isExpanded}">
  <div class="rosie-logo-sidebar-wrapper">
    <div [routerLink]="['/']" class="rosie-logo-white rosie-logo-sidebar"></div>
  </div>
  <ng-container *ngFor="let item of navSidebarItems; let last = last">
    <div class="d-flex sidebar-item align-items-center position-relative" [routerLink]="'/'+getKebabCaseKey(item.key)"
      (mouseenter)="onMouseEnter(item.key)" (mouseleave)="onMouseLeave(item.key)" #rla="routerLinkActive"
      [routerLinkActive]="['active']" (click)="handleSelection()" [ngClass]="{'mb-3': !last}">
      <img [src]="getIcon(item.key, rla.isActive || isHovered(item.key))" [alt]="getTranslationKey(item.key)"
        class="sidebar-icon" />
      <span>{{ getTranslationKey(item.key) | translate }}</span>
    </div>
  </ng-container>
  <div class="flex-full"></div>
  <div class="configuration-phone-number" *ngIf="hasActiveSubscription">
    <div class="phone-number">
      <img src="assets/images/phone.svg" class="mr-2">
      <a class="number" *ngIf="agentPhoneNumber" [href]="'tel:' + agentPhoneNumber">{{ agentPhoneNumber | phoneNumber
        }}</a>
      <span *ngIf="!agentPhoneNumber">
        <rosie-loader
          [config]="{show: true, customLoaderWrapperClass: 'rosie-dark-loader rosie-btn-loader'}"></rosie-loader>
      </span>
    </div>
    <div class="time-usage d-flex align-items-center" *ngIf="isTrialing">
      <circle-progress [percent]="percentsUsed" [maxPercent]="100" [outerStrokeColor]="strokeColor"></circle-progress>
      <span class="time-usage-text">{{
        minutesRemainingFormatted }}
        {{'SUBSCRIPTION.MINUTES_LEFT' | translate}}</span>
      <img src="assets/images/info.svg"
        [ngbTooltip]="'SUBSCRIPTION.MINUTES_LEFT_DESCRIPRION' | translate:{value: minutesRemainingFormatted}"
        tooltipClass="tooltip-wrapper content-tooltip description-tooltip" positionTarget=".time-usage" />
    </div>
    <div class="time-usage d-flex align-items-center" *ngIf="isSubscriptionActive">
      <circle-progress [percent]="percentsUsed" [maxPercent]="100" [outerStrokeColor]="strokeColor"></circle-progress>
      <span class="time-usage-text">
        {{ isOverages ? extraMinutes : minutesUsedFormatted }}
        {{ (isOverages ? 'SUBSCRIPTION.MINUTES_EXTRA' : 'SUBSCRIPTION.MINUTES_USED') | translate}}</span>
      <img src="assets/images/info.svg"
        [ngbTooltip]="(isOverages ? 'SUBSCRIPTION.MINUTES_EXTRA_DESCRIPTION' :'SUBSCRIPTION.MINUTES_USED_DESCRIPTION') | translate:{minutesUsed: minutesUsedFormatted, minutesIncluded: subscriptionData?.minutesIncluded, minutesRemaining: minutesRemaining, billingDate: subscriptionData?.billingDate | dateFormat, overageMinutes: extraMinutes, overageCost: userInformation?.subscription?.overageCost | rosieCurrency }"
        tooltipClass="tooltip-wrapper content-tooltip description-tooltip" positionTarget=".time-usage" />
    </div>
  </div>
  <div [dataHook]="'SIDEBAR.ACCOUNT.LOGOUT'" (click)="onLogoutClick()"
    class="d-flex sidebar-item align-items-center position-relative logout pointer">
    <img src="assets/images/offline.svg" class="sidebar-icon" />
    <span>{{ 'SIDEBAR.LOGOUT' | translate }}</span>
  </div>
</div>