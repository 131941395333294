import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { User, UserService } from '../core/user/user.service';
import { AuthenticationService, RegisterData } from '../core/auth/authentication-service';
import { DataStorage } from '../core/data/data-storage';
import { Business } from '../core/business/business';
import { getApiUrl } from '../core/helpers/rest-utils';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  storedGooglePlace: any;

  activeOnboardingStep: OnboardingStep = OnboardingStep.GoogleLookup;
  storedLead: OnboardingLead;
  persistentStorage = DataStorage.persistent();

  prefilledData: OnboardingPrefilledData = {} as OnboardingPrefilledData;

  constructor(private http: HttpClient, private user: UserService, private auth: AuthenticationService, private router: Router) {
  }

  get userData(): User {
    return this.user.userData;
  }

  get activeStep(): OnboardingStep {
    return this.activeOnboardingStep;
  }

  get activeBusiness(): Business {
    return this.user.activeBusiness;
  }

  get selectedPlace() {
    return this.storedGooglePlace || this.persistentStorage.get('googlePlace');
  }

  get completedSteps(): OnboardingStep[] {
    return this.persistentStorage.get('completedSteps') || [];
  }

  get lead(): OnboardingLead {
    return this.storedLead || this.persistentStorage.get('lead') || '';
  }

  setLead(lead: OnboardingLead): void {
    if (lead?.id) {
      this.storedLead = lead;
      this.persistentStorage.set('lead', lead)
    }
  }

  register(data: RegisterData): Observable<any> {
    data['leadId'] = this.lead.id;
    return this.auth.register(data).pipe(tap(() => this.persistentStorage.remove('lead')));
  }

  createLead(data: any): Observable<Business> {
    return this.http.post(`${getApiUrl()}leads`, data).pipe(map((lead: any) => lead.data), tap((lead: OnboardingLead) => this.setLead(lead)));
  }

  updateLead(data: { couponCode?: string, toltRefferal?: string; }) {
    const meta = data;
    return this.http.patch(`${getApiUrl()}leads/${this.lead.id}`, { meta }).pipe(map((lead: any) => lead.data), tap((lead: OnboardingLead) => this.setLead(lead)));
  }

  getLead(id?: string): Observable<OnboardingLead> {
    id = id;
    return this.http.get<OnboardingLead>(`${getApiUrl()}leads/${id}`).pipe(map((lead: any) => lead.data));
  }

  getActiveOnboardingStep(): string {
    return objectPropertiesToArray(OnboardingStep).find((step: string) => window.location.href.includes(step.toLowerCase())) as string;
  }

  goToStep(step: OnboardingStep, queryParam?: any): void {
    this.router.navigate(['/register', { step }], { queryParams: queryParam })
  }

  setSelectedPlace(place: any): void {
    this.storedGooglePlace = place;
    this.persistentStorage.set('googlePlace', place)
  }

  clearPlaceSelection() {
    this.storedGooglePlace = null;
    this.persistentStorage.remove('googlePlace')
  }

  prepareUrl(url: string = ''): string {
    if (url.includes('@')) {
      url = url.split('@').pop() || '';
    }
    return url.includes('https://') || url.includes('http://') ? url : `https://${url}`;
  }
}

export enum OnboardingStep {
  GoogleLookup = 'google_lookup',
  GoogleLookupVerification = 'google_lookup_verification',
  WebsiteUrl = 'website_url',
  BusinessInfo = 'business_info',
  Agent = 'agent',
  Preview = 'preview',
  User = 'user'
}
export interface OnboardingPrefilledData {
  websiteUrl: string,
}

export interface Clip {
  id: string;
  script: string;
  title: string;
  description: string;
}

export interface OnboardingLead {
  id: string;
  businessName: string;
  scrapedAt: Date | null;
  clipsGeneratedAt: Date | null;
  expiresAt: Date | null;
  url: string;
  placeId?: string;
  businessId: string | null;
  isLeadGenerated: boolean;
  clipScripts: Clip[];
}
