import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, distinctUntilChanged, filter, map, merge, tap } from 'rxjs';

@Component({
  selector: 'rosie-typeahead',
  templateUrl: './typeahead.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TypeaheadComponent {
  onFocus = new Subject<string>();
  onClick = new Subject<string>();
  latestOptionsShown: string[];

  @Input() options: any[] = [];
  @Input() placeholderTranslationKey: string = '';
  @Input() customClass: string = '';
  @Input() control: FormControl | AbstractControl = new FormControl();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor() { }

  search = (term: Observable<string>) => {
    const debouncedText = term.pipe(distinctUntilChanged());
    const clicksWithClosedPopup = this.onClick.pipe(filter(() => !this.instance?.isPopupOpen()), map(() => { this.scrollToSelectedOption() }));
    const inputFocus = this.onFocus.pipe(tap(() => { this.scrollToSelectedOption() }));

    return merge(debouncedText, clicksWithClosedPopup, inputFocus).pipe(
      map((term: string) => {
        if (term === this.control.value) {
          term = '';
        }
        return term?.length > 0 ? this.options.filter((option) => option.toLowerCase().indexOf(term.toLowerCase()) === 0) : this.options
      }),
      tap((availableOptions: string[]) => {
        this.latestOptionsShown = availableOptions;
      })
    );
  }

  clearSelection() {
    if (this.control.value) {
      this.control.patchValue('');
    }
  }

  selectFirstOption() {
    if (!this.control.value) {
      // this.control.patchValue(this.latestOptionsShown[0] || this.options[0]);
      // if (this.instance?.isPopupOpen()) {
      //   this.instance.dismissPopup();
      // }
    }
  }

  private scrollToSelectedOption() {
    setTimeout(() => {
      const inputElement = document.querySelector('.form-control.rosie-typeahead-input');
      if (inputElement) {
        const selectedValue = this.control.value;
        const dropdownElement = document.querySelector('.dropdown-menu.show');
        if (dropdownElement && selectedValue) {
          const optionElement = Array.from(dropdownElement.querySelectorAll('button')).find(
            option => option.textContent.trim() === selectedValue.trim()
          );
          if (optionElement) {
            optionElement.scrollIntoView({ block: 'nearest' });
          }
        }
      }
    }, 10);
  }
}
