import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { generateUniqueAdminId } from '../../core/helpers/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rosie-password-field',
  template: `
    <div class="form-group">
      <div class="input-group password-input-group">
        <input [type]="type" class="form-control" (focus)="handleOnFocus($event)" [formControl]="control" [id]="id" [dataHook]="label">
        <div class="input-group-append">
              <span class="input-group-text">
                <img src="{{icon}}" alt="eye" (click)="toggleRevealPassword()" [dataHook]="'reveal-password'" class="pointer">
                <!-- <fa-icon [icon]="icon" size="xs"
                         (click)="toggleRevealPassword()" [dataHook]="'reveal-password'"></fa-icon> -->
              </span>
        </div>
        <label *ngIf="labelKey" [for]="id" [ngClass]="'rosie-form-control-label'">
          {{ label | translate }}
        </label>
      </div>
      <rosie-validation-error *ngIf="showInlineRequiredError" [show]="control.errors?.required && control.touched"
                              [translationKey]="'COMMON.VALIDATION.REQUIRED'"
                              [translationAttributes]="{value: label}"></rosie-validation-error>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .input-group-text {
        position: relative;
        right: 18px;
        border: transparent;
        top: 11px;
        bottom: 11px;
        height: 28px;
        width: 28px;
        text-align: center;
        border-radius: 4px !important;
        display: flex;
        justify-content: center;
        background-color: transparent !important;
        font-size: 1.3rem;
        color: #9487A0;
      }

      .input-group-append {
        position: absolute;
        right: 0;
      }

      .password-input-group .form-control {
        border-radius: 50px !important;
      }
    `
  ]
})
export class PasswordFieldComponent {
  isRevealPasswordEnabled = false;

  @Input() labelKey = '';
  @Input() id: string = generateUniqueAdminId();
  @Input() control: UntypedFormControl;
  @Input() showInlineRequiredError = true;
  @Output() onFocus = new EventEmitter<Event>();


  constructor(private translate: TranslateService) {
  }

  get type(): string {
    return this.isRevealPasswordEnabled ? 'text' : 'password';
  }

  get icon(): string {
    return this.isRevealPasswordEnabled ? 'assets/images/eye-active.svg' : 'assets/images/eye.svg';
  }

  get label(): string {
    return this.translate.instant(this.labelKey);
  }

  toggleRevealPassword() {
    this.isRevealPasswordEnabled = !this.isRevealPasswordEnabled;
  }

  handleOnFocus(event) {
    this.control.markAsUntouched();
    this.onFocus.next(event);
  }
}
