<div class="install-update-modal-content h-100">
  <h5 class="modal-title">{{ getTranslationWithAppName(app ? 'INSTALL' : 'INSTALL_DEFAULT', true) }}</h5>
  <div class="modal-description dark">
    <div>
      <rosie-alert *ngIf="app?.hasError" [message]="getTranslationWithAppName('INSTALL_FAILED')" [type]="'error'"
        class="my-3">
      </rosie-alert>
      <div [innerHtml]="getAppSpecificTranslation(app ? 'INSTALL_DESCRIPTION' : 'INSTALL_DESCRIPTION_DEFAULT', !app)">
      </div>
    </div>
    <ng-content></ng-content>
    <ng-container
      [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
      [ngTemplateOutlet]="form">
    </ng-container>
  </div>
  <div *ngIf="!app?.hasCredentialsFlow" class="d-flex mt-6 action-section-install flex-wrap gap-2">
    <rosie-button (click)="close()" [customClass]="'btn-secondary btn-block'" [translationKey]="'COMMON.CANCEL'"
      class="d-block btn-install">
    </rosie-button>
    <rosie-button (click)="install.emit()" [fullWidth]="true" [inProgress]="app?.inProgress"
      [translationKey]="app?.hasExternalFlow ? getTranslationWithAppName('CONNECT_WITH_ACTION') : 'APP.CONNECT_ACTION'"
      class="d-block btn-install">
    </rosie-button>
  </div>
</div>