import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user/user.service';
import { OnboardingService } from '../onboarding/onboarding.service';
import { AnalyticsBrowser } from '@segment/analytics-next'

const analytics = AnalyticsBrowser.load({ writeKey: 'vfRBtbAIABlnLFiqstdoJguAmMrWPnFU' })
declare const fbq: any;
declare const ttq: any;
declare const gtag: any;

@Injectable({
  providedIn: 'root'
})

export class InternalAnalyticsService {
  constructor(private user: UserService, private onboarding: OnboardingService) {
    if (this.isProductionEnv) {
      this.initializeFacebookPixel();
      this.initializeGA();
      this.initializeTikTok();
      this.identify();
    }
  }

  get isProductionEnv(): boolean {
    return environment.name === 'production';
  }

  get toltReferral(): string {
    return window['tolt_referral'] || localStorage.getItem('tolt_referral') || '';
  }

  identify() {
    try {
      if (this.isProductionEnv && this.user?.id) {
        analytics.identify(this.user?.id, {
          name: this.user?.name,
          email: this.user?.email,
          plan: this.user.subscription?.planName
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  trackPage(pageTitle: string | AnalyticsPage) {
    try {
      if (this.isProductionEnv) {
        if (!this.user.hasActiveSubscription && !this.toltReferral) {
          this.initializeToltReferral();
        }
        const eventDetails = {
          userId: this.user?.id,
          name: pageTitle
        };
        analytics.page(eventDetails);
        this.handleFacebookPixelPageView(pageTitle as AnalyticsPage);
        this.handleTikTokPageView(pageTitle as AnalyticsPage);
        this.handleGAPageView(pageTitle as AnalyticsPage);
      }
    } catch (error) {
      console.log(error);
    }
  }

  trackEvent(eventName: AnalyticsEvent, eventDetails: any = {}) {
    try {
      if (this.isProductionEnv) {
        if (this.user?.id) {
          eventDetails.userId = this.user?.id;
        }
        analytics.track(eventName, eventDetails, this.user?.id as any);
        if (eventName === AnalyticsEvent.UserRegistered) {
          fbq('track', 'Lead');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  fireFirstTimeUserVisit(isSocialLogin = false) {
    try {
      if (this.isProductionEnv) {
        fbq('track', 'CompleteRegistration');
        ttq.identify({ "email": this.user?.email, "external_id": this.user?.id });
        ttq.track('CompleteRegistration', { "contents": [{ "content_id": "rosie" }] });
        gtag("event", "sign_up", { method: isSocialLogin ? "Google" : "email" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  private initializeFacebookPixel() {
    const facebookPixelEl = document.createElement('script');
    const facebookPixelNoScriptEl = document.createElement('noscript');
    facebookPixelEl.type = 'text/javascript';
    facebookPixelEl.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '891907839484716');
      fbq('track', 'PageView');
  `;
    facebookPixelNoScriptEl.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=26196183753362886&ev=PageView&noscript=1"/>`
    document.body.appendChild(facebookPixelEl);
    document.body.appendChild(facebookPixelNoScriptEl);
  }

  private initializeGA() {
    const GAInitializeEl = document.createElement('script');
    GAInitializeEl.async = true;
    GAInitializeEl.src = 'https://www.googletagmanager.com/gtag/js?id=G-8XN13HV4CR';
    const GAEl = document.createElement('script');
    GAEl.type = 'text/javascript';
    GAEl.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date()); 
        gtag('config', 'G-8XN13HV4CR');
  `;
    document.body.appendChild(GAInitializeEl);
    document.body.appendChild(GAEl);
  }

  private initializeTikTok() {
    const tikTokEl = document.createElement('script');
    tikTokEl.type = 'text/javascript';
    tikTokEl.innerHTML = `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
          ttq.load('CRC9E7RC77UBSLKS3BEG');
          ttq.page();
        }(window, document, 'ttq');
  `;
    document.body.appendChild(tikTokEl);
  }

  initializeToltReferral() {
    const tolReferralEl = document.createElement('script');
    tolReferralEl.type = 'text/javascript';
    tolReferralEl.async = true;
    tolReferralEl.setAttribute('src', 'https://cdn.tolt.io/tolt.js');
    tolReferralEl.setAttribute('data-tolt', 'b1fde686-cdf1-4a24-8410-ebae42dc1f49');

    tolReferralEl.onload = () => {
      setTimeout(() => {
        if (window['tolt_referral']) {
          localStorage.setItem('tolt_referral', window['tolt_referral']);
        }
      }, 2500);
    };

    document.body.appendChild(tolReferralEl);
  }


  private handleFacebookPixelPageView(pageTitle: AnalyticsPage) {
    if (pageTitle === AnalyticsPage.LeadGenerationStart || pageTitle === AnalyticsPage.LeadGenerationPreview || pageTitle === AnalyticsPage.UserRegistration) {
      fbq('track', 'ViewContent');
    }
    if (pageTitle === AnalyticsPage.UserRegistration) {
      fbq('track', 'Lead');
    }
  }

  private handleTikTokPageView(pageTitle: AnalyticsPage) {
    if (pageTitle === AnalyticsPage.LeadGenerationPreview || pageTitle === AnalyticsPage.LeadGenerationStart || pageTitle === AnalyticsPage.UserRegistration) {
      ttq.track('ViewContent', {
        "contents": [
          {
            "content_id": "rosie",
            "content_type": "product",
            "content_name": "package"
          }
        ]
      });
    }
  }

  private handleGAPageView(pageTitle: AnalyticsPage) {
    if (pageTitle === AnalyticsPage.LeadGenerationStart || pageTitle === AnalyticsPage.LeadGenerationPreview || pageTitle === AnalyticsPage.UserRegistration) {
      gtag('event', 'page_view', { 'page_title': pageTitle });
    }
    if (pageTitle === AnalyticsPage.UserRegistration) {
      gtag("event", "generate_lead", {
        lead_source: "Landing Page",
        items: [
          {
            item_id: "rosie",
          }
        ]
      });
    }
  }
}

export enum AnalyticsEvent {
  AppInstalled = 'app_installed',
  CallStarred = 'call_starred',
  CallUnstarred = 'call_unstarred',
  CallViewed = 'call_viewed',
  ClearRecordings = 'clear_recordings',
  SubscriptionInitialized = 'subscription_initialized',
  SubscriptionCreated = 'subscription_created',
  SubscriptionError = 'subscription_error',
  LeadGenerationStarted = 'lead_generation_started',
  LeadGenerationCompleted = 'lead_generation_completed',
  UserRegistered = 'user_registered',
}

export enum AnalyticsPage {
  LeadGenerationStart = 'lead_generation_start_page',
  LeadGenerationPreview = 'lead_generation_preview_page',
  UserRegistration = 'account_creation_page',
}
