import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UserService } from '../core/user/user.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from '../core/auth/authentication-service';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { Breakpoint, CaseType, convertCamelCaseToSnakeCase, fromMediaQuery } from '../core/helpers/utils';
import { environment } from '../../environments/environment';
import { interval, switchMap, Subscription } from 'rxjs';
import { AgentService } from '../core/agent/agent.service';
import { SubscriptionService } from '../core/subscription/subscription.service';
import { Subscription as Sub, SubscriptionStatus } from '../core/subscription/subscription';

@Component({
  selector: 'rosie-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit, OnDestroy {
  isMobileVersion: boolean = false;
  isExpanded: boolean = false;
  event: RouterEvent;
  navSidebarItems: NavSidebar[];
  private trialMinutesSubscription: Subscription;
  private hoverState: { [key: string]: boolean } = {};

  constructor(private router: Router, private user: UserService,
    private auth: AuthenticationService, private agent: AgentService, private subscription: SubscriptionService) {
    this.router.events.subscribe(event => {
      event instanceof NavigationEnd ? this.event = event : null;
    });
  }

  get hasActiveSubscription() {
    return this.user.hasActiveSubscription;
  }

  get userInformation(): User {
    return this.user.userData;
  }

  get activeBusiness() {
    return this.user.activeBusiness;
  }

  get isProduction(): boolean {
    return environment.name === 'production' || environment.name === 'sandbox';
  }

  get agentPhoneNumber(): string {
    return this.agent.primary?.phoneNumbers?.find(p => p.isPrimary)?.number;
  }

  get hasTrialMinutesLeft(): boolean {
    return this.agent.primary?.trialSecondsRemaining > 0;
  }

  get subscriptionData(): Sub {
    return this.user.subscription;
  }

  get availableMinutes(): number {
    return this.user.subscription.status === SubscriptionStatus.Trialing ? 50 : this.subscriptionData?.minutesIncluded;
  }

  get strokeColor(): string {
    return this.percentsUsed < 90 ? '#9332e0' : '#F43F5E';
  }

  get isTrialing(): boolean {
    return this.user.subscription.status === SubscriptionStatus.Trialing;
  }

  get isSubscriptionActive() {
    return this.user.subscription.status === SubscriptionStatus.Active;
  }

  get extraMinutes(): string {
    return this.formatSecondsAsMinutes((this.subscriptionData?.secondsUsed / 60 - this.availableMinutes) * 60);
  }

  get minutesRemaining(): number {
    const minutesRemainingInTrial = Math.floor(this.agent.primary?.trialSecondsRemaining / 60);
    const minutesRemainingOnPlan = this.availableMinutes - Math.floor(this.subscriptionData?.secondsUsed / 60);

    return this.isTrialing ? minutesRemainingInTrial : minutesRemainingOnPlan;
  }

  get minutesUsedFormatted() {
    return this.formatSecondsAsMinutes(this.subscriptionData?.secondsUsed);
  }

  get isOverages(): boolean {
    return this.percentsUsed >= 100;
  }

  get percentsUsed(): number {
    const minutesRemaining = Math.floor(this.agent.primary?.trialSecondsRemaining / 60);
    const percentsUsedTrial = Math.floor((this.availableMinutes - minutesRemaining) / this.availableMinutes * 100);
    const percentsUsedSubscription = Math.floor(this.subscriptionData?.secondsUsed / 60 / this.availableMinutes * 100);
    return this.isTrialing ? percentsUsedTrial : percentsUsedSubscription;
  }

  get minutesRemainingFormatted(): string {
    return this.formatSecondsAsMinutes(this.agent.primary?.trialSecondsRemaining);
  }

  onMouseEnter(key: NavSidebarItem): void {
    this.hoverState[key] = true;
  }

  onMouseLeave(key: NavSidebarItem): void {
    this.hoverState[key] = false;
  }

  isHovered(key: NavSidebarItem): boolean {
    return !!this.hoverState[key];
  }

  ngOnInit() {
    this.navSidebarItems = this.generateNavSidebarItems();
    this.handleTrialState();
    fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => this.isMobileVersion = matches);
  }

  ngOnDestroy(): void {
    if (this.trialMinutesSubscription) {
      this.trialMinutesSubscription.unsubscribe();
    }
  }

  onLogoutClick() {
    this.auth.revoke();
  }

  toggleMenu() {
    this.isExpanded = !this.isExpanded;
  }

  getTranslationKey(key: string): string {
    return `SIDEBAR.${key.replace(/[^a-zA-Z0-9 ]/g, '')?.toUpperCase()}`;
  }

  getKebabCaseKey(key: string): string {
    return convertCamelCaseToSnakeCase(key, CaseType.LowerCase).replace(/_/g, '-');
  }

  handleSelection() {
    if (this.isMobileVersion) {
      this.isExpanded = false;
    }
  }

  getIcon(key: NavSidebarItem, isActive?: boolean): string {
    return `assets/images/sidebar-${key}${isActive ? '-active' : ''}.svg`;
  }

  private generateNavSidebarItems(): NavSidebar[] {
    return objectPropertiesToArray(NavSidebarItem).map((key: NavSidebarItem) => {
      return { key: key };
    });
  }

  private formatSecondsAsMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
    return `${minutes}:${formattedSeconds}`;
  }

  private handleTrialState() {
    if (this.hasTrialMinutesLeft) {
      this.startTrialInterval();
    } else {
      this.subscription.created.subscribe(() => this.startTrialInterval());
    }
  }

  private startTrialInterval() {
    this.trialMinutesSubscription = interval(10000).pipe(switchMap(() => this.agent.getAgent())).subscribe();
  }
}

interface NavSidebar {
  key: NavSidebarItem;
  additionalIcon?: string;
  tooltipTranslationKey?: string;
}

enum NavSidebarItem {
  Calls = 'calls',
  Settings = 'settings',
  Integrations = 'integrations',
  Account = 'account'
}
