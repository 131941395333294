<h5 class="modal-title">{{ 'APP.EDIT_SETTINGS' | translate }}</h5>
<rosie-alert *ngIf="app?.hasError" [message]="getTranslationWithAppName('UPDATE_FAILED')" [type]="'error'" class="my-3">
</rosie-alert>
<div class="modal-description dark">
  <div class="app-section-heading-wrapper mb-4">
    <div
      [ngClass]="{ 'app-section-description': app && !app.hasPostInstallFields, 'app-section-heading': app?.hasPostInstallFields }">
      {{
        getTranslationWithAppName('SETTINGS_HEADING', true)
      }}
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container
    [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
    [ngTemplateOutlet]="form">
  </ng-container>
</div>
<div class="d-flex">
  <rosie-button (click)="close()" [customClass]="'btn-secondary btn-block'" [translationKey]="'COMMON.CANCEL'"
                class="d-block w-100 mr-2">
  </rosie-button>
  <rosie-button (click)="updateModalActionConfig.actionFunc()" [fullWidth]="true"
                [inProgress]="app?.inProgress" [translationKey]="updateModalActionConfig.translationKey"
                class="d-block w-100">
  </rosie-button>
</div>
