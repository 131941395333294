import { AfterContentChecked, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UserService } from '../core/user/user.service';
import { AnalyticsEvent, InternalAnalyticsService } from '../core/internal-analytics.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { DataStorage } from '../core/data/data-storage';
import { SubscriptionPage, SubscriptionService } from '../core/subscription/subscription.service';

@Component({
  selector: 'rosie-dashboard',
  template: `
    <div class="trial-banner" *ngIf="!hasActiveSubscription">
      <span class="text">{{'SUBSCRIPTION.CONTINUE_AGENT' | translate:{value: '12'} }}</span>
        <rosie-button *ngIf="!hasActiveSubscription" (click)="subscribe()" class="action"
          [translationKey]="'SUBSCRIPTION.UPGRADE_NOW'" [customClass]="'slimmer btn-secondary light'"></rosie-button>
    </div>
    <div class="trial-banner" *ngIf="hasActiveSubscription && hasLaunchInstructions">
      <img class="icon mr-1" src="assets/images/launch-light.svg">
      <span class="text">{{'ONBOARDING.INSTRUCTIONS.BANNER_HEADING' | translate:{value: '12'} }}</span>
        <rosie-button (click)="openInstructionModal()" class="action"
          [translationKey]="'ONBOARDING.INSTRUCTIONS.BANNER_ACTION'" [customClass]="'slimmer btn-secondary light'"></rosie-button>
      <img src="assets/images/close-x.svg" class="close-icon pointer" (click)="openHideInstructionsModal()">
    </div>
    <div class="d-flex rosie-dashboard-wrapper" [ngClass]="{'has-trial': !hasActiveSubscription || hasActiveSubscription && hasLaunchInstructions}">
      <div>
        <rosie-nav-sidebar *ngIf="!hasSidebar"></rosie-nav-sidebar>
      </div>
      <div [ngClass]="{ 'content-section': !hasSidebar}">
        <router-outlet></router-outlet>
      </div>
    <div>
    <ngx-smart-modal #subscriptionModal identifier="subscriptionModal" [dismissable]="true" [closable]="false" [backdrop]="true" [customClass]="'subscription-modal'" (onAnyCloseEvent)="handleSubscriptionCancel()">
        <rosie-subscription (onCancel)="handleSubscriptionCancel()" [page]="'main'"></rosie-subscription>
    </ngx-smart-modal>
    <ngx-smart-modal #instructionsModal identifier="instructionsModal" [dismissable]="true" [closable]="true" [backdrop]="true" [customClass]="'instructions-modal'">
        <h5 class="modal-title mb-2">{{ 'AGENT.LAUNCH' | translate }}</h5>
        <div class="modal-description dark">
          {{ 'AGENT.LAUNCH_DESCRIPTION' | translate }}
        </div>
        <div class="d-flex gap-2">
          <rosie-button (click)="hideLaunchInstructions()" [customClass]="'btn-secondary btn-block'"
                        [translationKey]="'AGENT.HIDE_LAUNCH_REMINDER'"
                        class="d-block w-100 mr-2">
          </rosie-button>
          <rosie-button (click)="openInstructions()" [translationKey]="'AGENT.VIEW_INSTRUCTIONS'"
                        class="d-block w-100">
          </rosie-button>
        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #hideInstructionsModal identifier="hideInstructionsModal" [dismissable]="true" [closable]="true" [backdrop]="true" [customClass]="'hide-instructions-modal'">
       <h5 class="modal-title mb-2">{{ 'AGENT.HIDE_LAUNCH_REMINDER' | translate }}</h5>
        <div class="modal-description dark">
          {{ 'AGENT.HIDE_LAUNCH_REMINDER_DESCRIPTION' | translate }}
        </div>
        <div class="d-flex gap-2">
          <rosie-button (click)="hideInstructionsModal.close()" [customClass]="'btn-secondary btn-block'"
                        [translationKey]="'COMMON.CANCEL'"
                        class="d-block w-100 mr-2">
          </rosie-button>
          <rosie-button (click)="hideLaunchInstructions()" [translationKey]="'AGENT.HIDE_LAUNCH_REMINDER'"
                        class="d-block w-100">
          </rosie-button>
        </div>
    </ngx-smart-modal>
  `,
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  @ViewChild('subscriptionModal') subscriptionModal: NgxSmartModalComponent;
  @ViewChild('instructionsModal') instructionsModal: NgxSmartModalComponent;
  @ViewChild('hideInstructionsModal') hideInstructionsModal: NgxSmartModalComponent;


  persistentStorage: DataStorage = DataStorage.persistent();
  hasSidebar = true;

  constructor(private router: Router, private route: ActivatedRoute, private user: UserService, private analytics: InternalAnalyticsService, private subscription: SubscriptionService, private modal: NgxSmartModalService) {
    this.handleNavSidebarVisibility();
  }

  get hasActiveSubscription(): boolean {
    return this.user.hasActiveSubscription;
  }

  get hasLaunchInstructions(): boolean {
    return this.persistentStorage.hasKey('enableLaunchInstructions');
  }

  subscribe() {
    this.analytics.trackEvent(AnalyticsEvent.SubscriptionInitialized);
    this.subscriptionModal.open();
    this.subscription.initialize.next(SubscriptionPage.Main);
    this.subscription.created.subscribe(() => this.subscriptionModal.close());
  }

  openInstructionModal() {
    this.instructionsModal.open();
  }

  openHideInstructionsModal() {
    this.hideInstructionsModal.open();
  }

  hideLaunchInstructions() {
    this.persistentStorage.remove('enableLaunchInstructions');
    this.modal.closeAll();
  }

  openInstructions() {
    window.open('https://heyrosie.com/support/en/articles/9717421-launching-rosie-for-your-business', '_blank');
  }

  handleSubscriptionCancel() {
    this.subscription.reset.next(SubscriptionPage.Main);
    this.subscriptionModal.close();
  }

  private handleNavSidebarVisibility(): void {
    this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      map(evt => {
        let routeHelper = this.route;
        while (routeHelper.firstChild) {
          routeHelper = routeHelper.firstChild;
        }
        return routeHelper;
      }))
      .subscribe(route => this.hasSidebar = !!(route.data as any).getValue()?.hideNavSidebar);
  }
}
