import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessive'
})
export class PossessivePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Check if the word ends with 's'
    return value.endsWith('s') ? value : value + "'s";
  }
}
