import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {runOnNextTick} from '@rallycommerce/common/utils';

@Component({
  selector: 'rosie-navigator',
  template: `
    <div class="d-flex align-items-center">
      <div class="icon pointer position-relative" (click)="navigateTo()" [dataHook]="'navigator=' + translationKey">
        <span class="d-block position-absolute" *ngIf="iconClass === 'close-icon'" [@rotateClose] [@.disabled]="animationDisabled"
              [ngClass]="iconClass"></span>
        <span class="d-block position-absolute" *ngIf="iconClass === 'chevron'" [@rotateChevron] [@.disabled]="animationDisabled"
              [ngClass]="iconClass"></span>
      </div>
    </div>
  `,
  styleUrls: ['./navigator.component.scss'],
  animations: [
    trigger('rotateClose', [
      transition(':enter', animate('200ms linear', keyframes([
        style({transform: 'rotate(45deg)', opacity: 0}),
        style({transform: 'rotate(0deg)', opacity: 1})
      ]))),
      transition(':leave', animate('200ms linear', keyframes([
        style({transform: 'rotate(0deg)', opacity: 1}),
        style({transform: 'rotate(45deg)', opacity: 0})
      ])))
    ]),
    trigger('rotateChevron', [
      transition(':enter', animate('200ms linear', keyframes([
        style({transform: 'rotate(-45deg)', opacity: 0}),
        style({transform: 'rotate(0deg)', opacity: 1})
      ]))),
      transition(':leave', animate('200ms linear', keyframes([
        style({transform: 'rotate(0deg)', opacity: 1}),
        style({transform: 'rotate(-45deg)', opacity: 0})
      ])))
    ])
  ]
})

export class NavigatorComponent implements AfterViewInit {
  @Input() url?: string;
  @Input() iconClass = 'close-icon';
  @Input() translationKey: string;
  @Output() navClicked = new EventEmitter<boolean>();

  animationDisabled = true;

  constructor(private router: Router) {
  }

  navigateTo() {
    this.url ? this.router.navigate([this.url]) : this.navClicked.emit(true);
  }

  ngAfterViewInit(): void {
    runOnNextTick(() => {
      this.animationDisabled = false;
    });
  }

}

export interface NavCustomClass {
  icon: string,
  label: string
}
