import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getApiUrl } from '../helpers/rest-utils';
import { map, Observable, of, tap } from 'rxjs';
import { Agent, AgentConfigOptions, AgentData, Booking, Configuration, CustomQuestion, TrainingFaq, TrainingFile } from './agent';
import { UserService } from '../user/user.service';
import { PhoneNumber } from '../phone/phone';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  config: AgentConfigOptions;
  primary: Agent;
  files: TrainingFile[] = [];
  faqs: TrainingFaq[] = [];
  customQuestions: CustomQuestion[] = [];

  constructor(private http: HttpClient, private user: UserService) {
  }

  get businessId(): string {
    return this.user.activeBusiness?.id;
  }

  createAgent(data?: AgentData): Observable<Agent> {
    return this.http.post(`${getApiUrl()}agents`, { title: 'Rosie', "configuration": { voice: "woman", tone: "casual" } }, { headers: { 'X-Business-ID': this.user.activeBusiness.id } })
      .pipe(
        map((res: any) => res.data),
        tap((agent: Agent) => {
          const user = this.user.userData;
          this.primary = agent;
          user.agent = agent;
          this.user.refreshUser(user);
        }));
  }

  getAgent(): Observable<Agent> {
    return this.http.get(`${getApiUrl()}agent`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(
        map((res: any) => res.data),
        tap((agent: Agent) => {
          const user = this.user.userData;
          user.agent = agent;
          this.primary = agent;
          this.user.refreshUser(user);
        }));
  }

  updateAgent(agent: Agent) {
    return this.http.put(`${getApiUrl()}agents/${this.primary.id}`, agent, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(
        map((res: any) => res.data),
        tap((agent: Agent) => {
          const user = this.user.userData;
          user.agent = agent;
          this.primary = agent;
          this.user.refreshUser(user);
        }));
  }

  updateAgentConfiguration(config: { configuration: Configuration }) {
    return this.http.patch(`${getApiUrl()}agents/${this.primary.id}/configuration`, config, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(
        map((res: any) => res.data),
        tap((configuration: Configuration) => {
          const user = this.user.userData;
          user.agent.configuration = configuration;
          this.primary.configuration = user.agent.configuration;
          this.user.refreshUser(user);
        }));
  }

  getConfigOptions(): Observable<AgentConfigOptions> {
    return this.http.get(`${getApiUrl()}agent/configuration`)
      .pipe(map((res: any) => {
        return { toneOptions: res?.data?.tone, voiceOptions: res?.data?.voice };
      }), tap((configOptions: AgentConfigOptions) => this.config = configOptions));
  }

  getFiles(): Observable<TrainingFile[]> {
    return this.http.get(`${getApiUrl()}files`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: any) => response.data), tap((files) => this.files = files || [])) as Observable<TrainingFile[]>;
  }

  updateFaqs(faqs: TrainingFaq[]): Observable<TrainingFaq[]> {
    return this.http.post(`${getApiUrl()}faqs/batch`, { faqs }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  updateCustomQuestions(questions: CustomQuestion[]): Observable<CustomQuestion[]> {
    return this.http.post(`${getApiUrl()}agent/${this.primary.id}/questions/batch`, { questions }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  getFaqs(): Observable<TrainingFaq[]> {
    return this.http.get(`${getApiUrl()}faqs`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: any) => response.data), tap((faqs) => this.faqs = faqs || [])) as Observable<TrainingFaq[]>;
  }

  getCustomQuestions(): Observable<CustomQuestion[]> {
    return this.http.get(`${getApiUrl()}agent/${this.primary.id}/questions`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: any) => response.data), tap((questions) => this.customQuestions = questions || [])) as Observable<CustomQuestion[]>;
  }

  uploadFile(file: File): Observable<string> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('X-Business-ID', this.user.activeBusiness?.id);
    formData.append('file', file);
    return this.http.post(`${getApiUrl()}files`, formData, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: any) => response?.data), tap((file) => this.files.push(file))) as Observable<string>;
  }

  deleteFile(id: string): Observable<any> {
    return this.http.delete(`${getApiUrl()}files/${id}`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: any) => response?.data), tap(() => this.files = this.files.filter(file => file.id !== id)));
  }

  updateBookingMessage(bookingMessage: Booking) {
    if (!bookingMessage.id) { delete bookingMessage.id; }
    return this.http.post(`${getApiUrl()}booking-messages?yolo`, bookingMessage, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
  }

  updateBlockedPhoneNumbers(phoneNumbers: PhoneNumber[]) {
    return this.http.post(`${getApiUrl()}phone-numbers/blocked/batch`, { phoneNumbers }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } });
  }
}
