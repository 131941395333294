<div class="d-flex justify-content-center align-items-center flex-column info-tile-container">
  <div [ngClass]="{'default': isDefaultIcon}" class="icon">
    <img [src]="'assets/images/'+icon" />
  </div>
  <div class="heading mt-6">{{ headingTranslationKey | translate }}</div>
  <div class="description mt-2">
    <span *ngFor="let description of descriptions">
      <ng-container *ngIf="description?.url else text;">
        <span (click)="navigateTo(description?.url)" class="text-decoration-underline pointer">{{
          description?.title
          | translate
          }}</span>
      </ng-container>
      <ng-template #text>{{ description | translate }}</ng-template>
    </span>
  </div>
</div>