import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  reset = new Subject<boolean>();

  constructor() {
  }
}
