import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {runWithDelay} from '@rallycommerce/common/utils';

/**
 *
 * A directive to scroll an element into view after it's initialized.
 *
 */

@Directive({
  selector: '[scrollIntoViewOnInit]'
})
export class ScrollIntoViewOnInitDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      runWithDelay(() => {
        this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }, 100);
    });
  }
}
