import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {generateUniqueAdminId} from '../../core/helpers/utils';
import {TextType} from '../text/text.component';

@Component({
  selector: 'rosie-text-input-addon',
  template: `
    <ng-container *ngIf="control">
      <div class="text-input-addon-container" [ngClass]="customClass">
        <div class="input-group">
          <div class="input-group-prepend" *ngIf="prefix">
            <span class="input-group-text disabled-text" [id]="id">{{ prefix }}</span>
          </div>
          <input [type]="type" [formControl]="control"
                 placeholder="{{placeholderTranslationKey | translate}}" [id]="id"
                 class="rosie-form-control form-control" [ngClass]="{'border-left-0': prefix, 'has-label': !!labelTranslationKey}">
          <div class="input-group-append" [ngClass]="{'absolute-suffix': absoluteSuffix}" *ngIf="suffix">
            <span class="input-group-text border-left-0 disabled-text" [id]="id">{{ suffix }}</span>
          </div>
          <label *ngIf="labelTranslationKey" [for]="id" class="rosie-form-control-label" [ngClass]="{'floating-label' : floatingLabel}">
            {{ labelTranslationKey | translate }}
          </label>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./text-input-addon.component.scss']
})
export class TextInputAddonComponent {
  @Input() id = generateUniqueAdminId();
  @Input() disabled = false;
  @Input() type: TextType = TextType.Text;
  @Input() placeholderTranslationKey = '';
  @Input() labelTranslationKey = '';
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() absoluteSuffix = false;
  @Input() floatingLabel = false;
  @Input() customClass = '';

  constructor() {
  }
}

