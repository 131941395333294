import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScriptLoaderService } from '../../core/helpers/script-loader.service';
import { forkJoin, Subject, tap } from 'rxjs';
import { SubscriptionService } from '../../core/subscription/subscription.service';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Business } from '../../core/business/business';
import { UserService } from '../../core/user/user.service';
import { Router } from '@angular/router';
import { Subscription, SubscriptionData, SubscriptionType } from '../../core/subscription/subscription';
import { AnalyticsEvent, InternalAnalyticsService } from '../../core/internal-analytics.service';
import { DataStorage } from '../../core/data/data-storage';
import { RosieCurrencyPipe } from '../pipes/rosie-currency.pipe';

declare const Stripe;

@Component({
  selector: 'rosie-subscription',
  templateUrl: 'subscription.component.html',
  styleUrls: ['subscription.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('cardElement') cardElement: ElementRef;
  @Output() onCancel = new Subject<boolean>();
  @Input() page: string;
  persistentStorage: DataStorage = DataStorage.persistent();
  stripe: any;
  mainStripeElement: any;
  stripeElements: any;

  cardholderForm: UntypedFormGroup;
  paymentError: string;

  hasPromotionExpand: boolean = false;
  inProgress: boolean;
  isInitializingPaymentMethod = true;
  invalidState = new Subject<boolean>();
  selectedPlan;
  selectedPlanControl = new FormControl(true);

  constructor(private scriptService: ScriptLoaderService, private subscription: SubscriptionService, private router: Router, private currency: RosieCurrencyPipe,
    private translate: TranslateService, private user: UserService, private formBuilder: UntypedFormBuilder, private analytics: InternalAnalyticsService, private cdr: ChangeDetectorRef) {
  }

  get activeBusiness(): Business {
    return this.user.activeBusiness;
  }

  get plans() {
    return this.subscription.plans;
  }

  get licensedPlanPrice() {
    return this.selectedPlan?.prices.find((price) => price?.usageType === SubscriptionType.Licensed);
  }

  get meteredPlanPrice() {
    return this.selectedPlan?.prices.find((price) => price?.usageType === SubscriptionType.Metered);
  }

  get planName() {
    return this.selectedPlan?.name;
  }

  get isPageActive() {
    return this.page === this.subscription.type;
  }

  ngOnInit(): void {
    this.cardholderForm = this.formBuilder.group({
      couponCode: '',
      authorizePayment: [true, Validators.required],
    });
    this.subscription.initialize.pipe(tap((page) => this.subscription.type = page)).subscribe(() => this.initializeStripe());
    this.subscription.reset.pipe(tap((page) => this.subscription.type = page)).subscribe(() => this.clearForm());
  }

  ngOnDestroy(): void {
  }

  ngAfterContentInit(): void {
    if (this.plans?.length > 0) {
      const defaultPlan = this.plans.find((plan) => plan?.name?.toLowerCase()?.includes('professional'));
      this.selectPlan(defaultPlan);
    }
  }

  selectPlan(plan: Subscription) {
    this.selectedPlan = plan;
  }

  getErrorElId(fieldId: string): string {
    return `${fieldId}-error`;
  }

  cancel() {
    this.onCancel.next(true);
  }

  addPromotionCode() {
    this.hasPromotionExpand = !this.hasPromotionExpand;
  }

  saveCreditCard() {
    if (this.isInitializingPaymentMethod || !this.isPageActive) {
      return;
    }

    this.cardholderForm.markAllAsTouched();
    this.invalidState.next(true);

    if (this.isCreditCardFormValid()) {
      this.inProgress = true;
      this.stripe.confirmCardSetup(this.subscription.credentials.clientSecret,
        {
          payment_method: {
            card: this.mainStripeElement,
          },
        }
      ).then((result) => {
        if (result.error) {
          this.paymentError = result.error.message;
          this.analytics.trackEvent(AnalyticsEvent.SubscriptionError, { message: typeof this.paymentError === 'string' ? this.paymentError : JSON.stringify(this.paymentError), userEmail: this.user.email });
          this.inProgress = false;
        } else {
          this.subscription.create(result.setupIntent.payment_method, this.selectedPlan.prices, this.cardholderForm.get('couponCode')?.value)
            .subscribe((subscription: SubscriptionData) => {
              this.analytics.trackEvent(AnalyticsEvent.SubscriptionCreated, { selectedPlan: this.selectedPlan.name });
              this.persistentStorage.set('enableLaunchInstructions', true);
              this.subscription.created.next(true);
              this.inProgress = false;
            }, (error) => {
              this.subscription.getBilling().subscribe(() => {
                this.paymentError = error?.toLowerCase()?.includes('coupon') ? 'Promotion code invalid. Please re-enter or remove code.' : `${error}`;
                this.inProgress = false;
              });
            });
        }
      });
    }
  }

  initializeStripe() {
    if (!this.isPageActive) return;
    this.isInitializingPaymentMethod = true;
    this.subscription.paymentMethodErrors = ['card-number', 'card-expiry', 'card-cvc'];
    const script = { src: 'https://js.stripe.com/v3/', name: 'stripe', };
    forkJoin([this.scriptService.load(script)])
      .subscribe(() => {
        console.log('Stripe loaded');
        this.stripe = Stripe(this.subscription.credentials.publishableKey);
        this.stripeElements = this.stripe.elements({ clientSecret: this.subscription.credentials.clientSecret });
        this.setupFields();
      });
  }

  clearForm() {
    this.invalidState.next(false);
    this.paymentError = '';
  }

  redirectToDashboard() {
    this.router.navigateByUrl('');
  }

  getPlanOptions() {
    return [{
      value: true, label: `${this.planName} ${this.translate.instant('SUBSCRIPTION.INITIAL_PLAN',
        { minutes: this.selectedPlan.minutesIncluded, value: this.currency.transform(this.licensedPlanPrice?.amount), pricePerMinute: this.currency.transform(this.meteredPlanPrice?.amount) })}`
    }];
  }

  private setupFields() {
    this.getStripeFields().forEach((inputFieldConfig: PaymentOptionField) => {
      const fieldElement = this.stripeElements.create(inputFieldConfig.customElement, style);
      const fieldErrorElement = document.getElementById(`${inputFieldConfig.id}-error`);

      fieldElement.mount(`#${inputFieldConfig.id}`);
      if (inputFieldConfig.mainElement) {
        this.mainStripeElement = fieldElement;
      }

      fieldErrorElement.textContent = `${this.translate.instant(inputFieldConfig.label)} is required`;

      this.handleFieldOnChange(fieldElement, inputFieldConfig, fieldErrorElement);
      this.handleFieldOnFocus(fieldElement, inputFieldConfig);

      this.invalidState.subscribe((isInvalid: boolean) => {
        if (isInvalid) {
          baseCssClasses.add(FormCssClasses.Touched);
        } else {
          baseCssClasses.delete(FormCssClasses.Touched);
          baseCssClasses.delete(FormCssClasses.Dirty);
        }
        fieldElement.update({ classes: { base: [...baseCssClasses].join(' ') } });
      });
    });
    this.isInitializingPaymentMethod = false;
    this.mainStripeElement.on('ready', () => this.isInitializingPaymentMethod = false);
  }

  private handleFieldOnChange(fieldElement: any, inputFieldConfig: PaymentOptionField, fieldErrorElement: any) {
    fieldElement.on('change', (event) => {
      if (!event.empty) {
        baseCssClasses.add(FormCssClasses.Dirty);
      } else {
        baseCssClasses.delete(FormCssClasses.Dirty);
      }
      if (!event.complete) {
        baseCssClasses.add(FormCssClasses.Invalid);
        baseCssClasses.delete(FormCssClasses.Valid);
      } else {
        baseCssClasses.delete(FormCssClasses.Invalid);
        baseCssClasses.add(FormCssClasses.Valid);
      }
      fieldElement.update({ classes: { base: [...baseCssClasses].join(' ') } });

      if (event.error) {
        fieldErrorElement.textContent = event.error.message;
        this.subscription.paymentMethodErrors.push(inputFieldConfig.id);
      } else {
        fieldErrorElement.textContent = '';
        this.subscription.paymentMethodErrors = this.subscription.paymentMethodErrors.filter(error => error !== inputFieldConfig.id);
      }
    });
  }

  private handleFieldOnFocus(fieldElement: any, inputFieldConfig: PaymentOptionField) {
    fieldElement.on('focus', (event) => {
      this.subscription.paymentMethodErrors = this.subscription.paymentMethodErrors.filter(error => error !== inputFieldConfig.id);
      baseCssClasses.add(FormCssClasses.Touched);
      if (!baseCssClasses.has(FormCssClasses.Dirty)) {
        baseCssClasses.add(FormCssClasses.Invalid);
      } else {
        baseCssClasses.delete(FormCssClasses.Invalid);
      }
      fieldElement.update({ classes: { base: [...baseCssClasses].join(' ') } });
    });
  }

  private isCreditCardFormValid(): boolean {
    return this.subscription.paymentMethodErrors?.length === 0 && !this.inProgress;
  }

  private getStripeFields(): PaymentOptionField[] {
    return [
      {
        id: 'card-number',
        label: 'SUBSCRIPTION.CARD_NUMBER_LABEL',
        customElement: StripeCustomElement.CardNumber,
        mainElement: true,
        cardNumberField: true,
        class: 'rosie-form-control form-control',
      },
      {
        id: 'card-expiry',
        label: 'SUBSCRIPTION.EXPIRY_DATE_LABEL',
        customElement: StripeCustomElement.CardExpiry,
        specificErrorMsg: true,
        class: 'rosie-form-control form-control'
      },
      {
        id: 'card-cvc',
        label: 'SUBSCRIPTION.CVC_LABEL',
        customElement: StripeCustomElement.CardCvc,
        class: 'rosie-form-control form-control'
      }
    ];
  }
}


export enum FormCssClasses {
  Control = 'form-control',
  Focus = 'ng-focus',
  Dirty = 'ng-dirty',
  Invalid = 'ng-invalid',
  Valid = 'ng-valid',
  Touched = 'ng-touched',
  Empty = 'ng-empty'
}

export enum PaymentCssClasses {
  CCIcon = 'rosie-credit-card-icon'
}

export enum StripeCustomElement {
  CardNumber = 'cardNumber',
  CardExpiry = 'cardExpiry',
  CardCvc = 'cardCvc',
}

export interface PaymentOptionField {
  id: string;
  label: string;
  customElement?: string;
  mainElement?: boolean;
  class?: string;
  cardholderName?: boolean;
  ownCardholderName?: boolean;
  cardNumberField?: boolean;
  specificErrorMsg?: boolean;
}


export const baseCssClasses = new Set<string>([FormCssClasses.Control]);
export const style = {
  placeholder: '',
  classes: {
    focus: FormCssClasses.Focus,
    invalid: FormCssClasses.Invalid,
    empty: `${FormCssClasses.Empty} ${FormCssClasses.Invalid}`,
    base: [...baseCssClasses].join(' ')
  },
  style: {
    invalid: {
      color: '#000',
      iconColor: '#000',
      lineHeight: '20px'
    },
    complete: {
      lineHeight: '20px'
    },
    base: {
      lineHeight: '50px',
      ':focus': {
        lineHeight: '20px'
      }
    }
  }
};
