import { Business, BusinessInformation } from './business';

export const buildBusiness = (business: any): Business => {
  return {
    title: business.title,
    timezone: business.timezone,
    url: business.url,
    id: business.id,
    meta: business.meta,
    information: buildBusinessInformation(business.information),
  };
};

export const buildBusinessInformation = (businessInfo: any): BusinessInformation => {
  return {
    description: businessInfo.description,
    url: businessInfo.url || '',
    googleMapsUrl: businessInfo.googleMapsUrl || '',
    phone: businessInfo.phone,
    additionalProperties: businessInfo.additionalProperties,
    meta: businessInfo.meta,
    id: businessInfo.id,
  };
};
