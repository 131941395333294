import { Component, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rosie-hover',
  template: `
    <div class="hover-container position-relative" ngbDropdown [autoClose]="'outside'"
         #hoverDropdown="ngbDropdown" x-placement="bottom-left"
         (mouseenter)="$event.stopPropagation(); hoverDropdown.open(); show(); handleDropdownClosing();"
         container="body">
      <div class="hover-trigger pointer mr-1" ngbDropdownToggle id="call-preview">
        <img class="disable-click" src="/..assets/images/call-status-circle.svg"/>
      </div>
      <div class="hover-content" ngbDropdownMenu aria-labelledby="call-preview">
        <div class="inner pl-4 pr-4"
             (mouseleave)="$event.stopPropagation(); hoverDropdown.close(); hide();">
          <ng-content></ng-content>
        </div>
      </div>
    </div>`,
  styleUrls: ['./hover.component.scss']
})
export class HoverComponent {
  @Input() isVisible = false;
  @Input() isLoading = false;
  @Output() updateContentVisibility = new EventEmitter<boolean>();
  @ViewChild('hoverDropdown') hoverDropdown: NgbDropdown;
  private hoverTracker: Function;
  private insidePreviewClasses: string[] = ['hover-container', 'hover-content', 'inner', 'disable-click'];

  constructor(private renderer: Renderer2) {
  }

  show() {
    this.updateContentVisibility.next(true);
  }

  hide() {
    this.updateContentVisibility.next(false);
    this.hoverTracker();
  }

  handleDropdownClosing() {
    this.hoverTracker = this.renderer.listen('document', 'mousemove', e => {
      if (!this.isHoverInsideContent(e.composedPath())) {
        e.stopPropagation();
        this.hoverDropdown.close();
        this.hide();
      }
    });
  }

  private isHoverInsideContent(path: EventTarget[]): boolean {
    return path?.some((el: Element) => !!this.insidePreviewClasses.some(className => el?.classList?.contains(className)));
  }
}
