import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'rosie-svg',
  template: `<span [innerHTML]="svgIcon"></span>`,
  encapsulation: ViewEncapsulation.None
})
export class SvgIconComponent implements OnInit {

  @Input() iconSrc?: string;

  svgIcon: SafeHtml;

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    if (!this.iconSrc) {
      this.svgIcon = '';
      return;
    }
    this.httpClient
      .get(`assets/images/${this.iconSrc}`, { responseType: 'text' })
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      });
  }

}
