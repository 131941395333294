import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../core/user/user.service';
import { timeZones } from '../configuration/configuration';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    constructor(private datePipe: DatePipe, private user: UserService) { }

    get businessTimezone(): string {
        return this.user.activeBusiness?.timezone;
    }

    get timezone(): string {
        return timeZones.find(tz => tz.value === this.businessTimezone)?.timezone;
    }

    transform(value: string, fullDate?: boolean): string {
        const inputDate = new Date(value);
        const currentDate = new Date();
        const timezone = this.timezone;

        if (!value) {
            return '';
        }

        if (fullDate) {
            const dayOfWeek = this.datePipe.transform(inputDate, 'EEE', timezone);
            const dateStringFormatted = this.datePipe.transform(inputDate, 'MMM d', timezone);
            const timeString = this.datePipe.transform(inputDate, 'h:mm a', timezone)?.replace(' ', '');
            const daysAgo = this.getDaysAgo(inputDate, currentDate);

            return `${dayOfWeek}, ${dateStringFormatted}, ${timeString} (${daysAgo})`;
        } else {
            if (this.isSameDay(inputDate, currentDate)) {
                return this.datePipe.transform(value, 'h:mm a', timezone)?.replace(' ', '')!;
            } else {
                return this.datePipe.transform(value, 'M/d/yy', timezone)!;
            }
        }
    }

    private getDaysAgo(inputDate: Date, currentDate: Date): string {
        const diffInTime = currentDate.getTime() - inputDate.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) {
            return 'Today';
        } else if (diffInDays === 1) {
            return '1 day ago';
        } else {
            return `${diffInDays} days ago`;
        }
    }

    private isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }
}
