<ng-container *ngIf="control && options?.length > 0">
    <div class="typeahead-wrapper">
        <input [name]="''" [editable]="false" [autocomplete]="'off'" [formControl]="control" [ngbTypeahead]="search"
            [focusFirst]="false" [selectOnExact]="true" [class]="'form-control rosie-typeahead-input'"
            (focus)="onFocus.next($any($event).target.value)" [autocomplete]="'new-password'"
            (click)="onClick.next($any($event).target.value)" (blur)="selectFirstOption()"
            [ngClass]="{'active': instance?.isPopupOpen()}" #instance="ngbTypeahead"
            [placeholder]="placeholderTranslationKey | translate">
        <img class="typeahead-chevron" src="assets/images/chevron.svg">
    </div>
</ng-container>