import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { generateUniqueAdminId } from '../../core/helpers/utils';

@Component({
  selector: 'rosie-radio-group',
  template: `
    <div class="radio-group-title mb-3" *ngIf="title">{{ title | translate }}</div>
    <div class="radio-group-option-container d-flex flex-wrap align-items-center gap-2">
      <label *ngFor="let option of options; let last = last;" class="d-flex justify-content-start align-items-start pointer">
        <input type="radio" [value]="option.value" name="radioGroup-{{title}}"
               [ngClass]="{'checked': isChecked(option.value) || initialValue === option.label}" (change)="updateValue(option.value)"/>
        <div class="radio-info-wrapper">
          <div class="radio-option-label" *ngIf="option.label">{{ option.label | translate }}</div>
          <div class="radio-option-description" *ngIf="option.description">{{ option.description | translate }}</div>
        </div>
      </label>
    </div>
  `,
  styleUrls: ['./radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioGroupComponent {
  form: FormGroup;

  @Input() id = generateUniqueAdminId();
  @Input() customClass: string;
  @Input() title = '';
  @Input() options: RadioOption[] | [] | any = [];
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() dataHook = 'radio-group';
  @Output() valueChanged: EventEmitter<string | number> = new EventEmitter<string | number>();

  constructor() {
  }

  get initialValue(): string | number {
    return this.control.value || this.options[0].value;
  }

  updateValue(value: string | number) {
    this.control.setValue(value);
    this.control.markAsDirty();
    this.valueChanged?.emit(value);
  }

  isChecked(value: string | number): boolean {
    return value === this.control.value;
  }
}

export interface RadioOption {
  label: string;
  value: string;
  description?: string;
}

