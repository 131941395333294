<h5 class="modal-title">{{ getAppStatusTranslation('SETTINGS', true) }}</h5>
<rosie-alert *ngIf="showSuccessMessage" [customClass]="'mb-6'" [message]="getAppStatusTranslation('DESCRIPTION', true)"
  [type]="'success'" class="my-3"></rosie-alert>
<rosie-alert *ngIf="app?.isPending && !app?.inProgress" [message]="getAppStatusTranslation('INCOMPLETE_WARNING')"
  [type]="'warning'" class="my-3"></rosie-alert>
<div *ngIf="app && app?.isPending" class="app-section-heading-wrapper mb-4">
  <div [innerHtml]="'APP.STATUS.PENDING.SETTINGS_DESCRIPTION' | translate:{value: this.app?.name }"
    class="app-section-description"></div>
</div>
<div *ngIf="app && !app?.hasAnyEditableFieldsOnceInstalled && !app?.isPending" class="app-section-heading-wrapper mb-4">
  <div [innerHtml]="getAppSpecificTranslation('SETTINGS_DESCRIPTION')" class="app-section-description"></div>
</div>
<div *ngIf="app?.inProgress" class="mb-3">
  <rosie-loader [config]="{show: app?.inProgress, customLoaderWrapperClass: 'rosie-dark-loader', size: 'lg'}">
  </rosie-loader>
</div>
<div *ngFor="let field of readOnlyFields; let last=last; let first=first"
  [ngClass]="{'last': last, 'first': first, 'app-field': true}">
  <rosie-display-field [behaviour]="field.behaviour" [helpTextKey]="getHelpTextPerField(field.name)"
    [icon]="field.value ? getFieldIconValue(field.name) : ''" [labelKey]="field.name" [value]="field.value">
  </rosie-display-field>
</div>

<div *ngIf="app?.hasAnyEditableFieldsOnceInstalled">
  <div class="app-section-heading-wrapper mb-4  mt-6">
    <div class="app-section-heading">{{ getAppStatusTranslation('SETTINGS_HEADING', true) }}</div>
  </div>
  <div *ngFor="let field of editableFields; let last=last; let first=first" [ngClass]="{'last': last, 'first': first}"
    class="app-field">
    <rosie-display-field [behaviour]="field.behaviour" [helpTextKey]="getHelpTextPerField(field.name)"
      [icon]="field.value ? getFieldIconValue(field.name) : ''" [labelKey]="field.name" [value]="field.value">
    </rosie-display-field>

  </div>
  <div *ngIf="isReauthorizeAllowed" class="app-field reauthorize">
    <div class="d-flex align-items-center"><i class="refresh-icon mr-2"></i>{{
      getAppStatusTranslation('LAST_AUTHORIZED') }},
      {{ getFormattedCreatedAt() }}
    </div>
    <div class="action-button">
      <rosie-button (click)="install.emit()" [customClass]="'reauthorize-button'" [darkLoader]="true" [fullWidth]="true"
        [translationKey]="getAppStatusTranslation('RE_AUTHORIZE')"></rosie-button>
    </div>
  </div>
</div>
<div class="d-flex mt-6">
  <ng-container *ngIf="app?.isInstalled">
    <rosie-button (click)="openUninstallModal()" *ngIf="app?.hasAllowUninstall" [customClass]="'btn-secondary danger'"
      [fullWidth]="true" [translationKey]="'APP.UNINSTALL'" class="w-100 mr-2">
      <i class="delete-icon"></i>
    </rosie-button>
    <ng-container *ngIf="settingsModalActionConfig">
      <rosie-button (click)="settingsModalActionConfig.actionFunc()"
        *ngIf="(app?.hasAnyEditableFieldsOnceInstalled || isReauthorizeAllowed) && !hasHiddenEdit"
        [customClass]="'btn-secondary'" [fullWidth]="true" [translationKey]="settingsModalActionConfig.translationKey"
        class="w-100">
        <i [ngClass]="settingsModalActionConfig.iconClass"></i>
      </rosie-button>
    </ng-container>

  </ng-container>
  <ng-container *ngIf="app?.isPending">
    <rosie-button (click)="close()" [customClass]="'btn-secondary'" [fullWidth]="true"
      [translationKey]="'COMMON.CANCEL'" class="w-100 mr-2">
    </rosie-button>
    <rosie-button (click)="verify.emit()" [customClass]="'btn-primary'" [fullWidth]="true"
      [inProgress]="app?.inProgress" [translationKey]="getAppStatusTranslation('SETTINGS_ACTION')" class="w-100">
    </rosie-button>
  </ng-container>
</div>