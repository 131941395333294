import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'rosie-input-view',
  template: `
    <label [attr.for]="label" *ngIf="label">{{ label | translate }}</label>
    <input type="text" [attr.id]="label" [formControl]="formControl" (blur)="blurEvent.emit()"
           *ngIf="formControl" [readonly]="readonly" [class]="{'form-control-plaintext': readonly, 'form-control': !readonly}">
  `,
})
export class InputViewComponent implements OnInit {

  @Input() label: string;
  @Input() formControl: string;
  @Input() readonly = false;
  @Output() blurEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
