<div class="settings-wrapper">
  <div class="settings-heading">{{ 'SETTINGS_SECTION.HEADING' | translate }}</div>
  <ng-container [ngSwitch]="activeSettingsSection">
    <div *ngSwitchCase="SettingsSection.AccountInfo" class="view-wrapper">
      <div class="section-wrapper">
        <!-- <div class="section-heading">{{ 'SETTINGS_SECTION.ACCOUNT.HEADING' | translate }}</div> -->
        <div class="section-content">
          <div class="item d-flex justify-content-between align-items-center pt-0">
            <h6>{{ 'SETTINGS_SECTION.ACCOUNT.EMAIL' | translate }}</h6>
            <div class="d-flex justify-content-end align-items-center">
              <div class="text-align-right">
                <div>{{'SETTINGS_SECTION.ACCOUNT.RECEIVE_CALL_NOTIFICATIONS' | translate}}</div>
                <div class="d-flex align-items-center justify-content-end"><span class="mr-4">{{ userData?.email
                    }}</span>
                  <rosie-switch [control]="notificationForm?.get('emailNotificationEnabled')"
                    [name]="'EMAIL_NOTIFICATION_ENABLED'" class="notification-switch"></rosie-switch>
                </div>
                <div class="d-flex align-items-center justify-content-end"><span class="mr-2"
                    [ngClass]="{'text-underline dark-link pointer': !phoneNumber}" (click)="phoneModal.open()">{{
                    phoneNumber ?
                    (phoneNumber |
                    phoneNumber) : ('SETTINGS_SECTION.ACCOUNT.ADD_PHONE' | translate)}}</span>
                  <img *ngIf="phoneNumber" class="pointer mr-2 icon" [src]="'assets/images/edit.svg'"
                    [name]="'EDIT_ACCOUNT_PHONE_NUMBER'" (click)="phoneModal.open()">
                  <rosie-switch [control]="notificationForm?.get('smsNotificationEnabled')"
                    [name]="'SMS_NOTIFICATION_ENABLED'" class="notification-switch"></rosie-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="item d-flex justify-content-between align-items-center">
            <h6>{{ 'SETTINGS_SECTION.ACCOUNT.SUBSCRIPTION_PLAN' | translate }}</h6>
            <div class="d-flex justify-content-end align-items-center">
              <ng-container *ngIf="hasActiveSubscription">
                <div class="mr-3">
                  {{ subscriptionData?.planName }} plan<br>
                  ${{subscriptionData?.planCost}}/month for up to {{subscriptionData?.minutesIncluded}} minutes<br>
                  {{subscriptionData?.overageCost | rosieCurrency}} per additional minute.
                  Need more minutes? <span class="text-underline dark-link pointer contact-us-intercom-trigger"
                    id="contact-us-intercom-trigger">Contact
                    us</span>
                </div>
                <img (click)="redirectToBillingPortal()" class="d-block pointer" [src]="'assets/images/edit.svg'"
                  [name]="'EDIT_SUBSCRIPTION_PLAN'" [dataHook]="'SETTINGS_SECTION.ACCOUNT.SUBSCRIPTION_PLAN'">
              </ng-container>
              <ng-container *ngIf="!hasActiveSubscription">
                <rosie-button (click)="subscribe()" [translationKey]="'SETTINGS_SECTION.ACCOUNT.SUBSCRIBE'"
                  [customClass]="'btn-slim'"></rosie-button>
              </ng-container>
            </div>
          </div>
          <div class="item d-flex justify-content-between align-items-center">
            <h6>{{ 'SETTINGS_SECTION.ACCOUNT.RECORDINGS' | translate }}</h6>
            <a (click)="clearRecordingModal.open()" [translationKey]="'SETTINGS_SECTION.ACCOUNT.CLEAR_RECORDINGS'"
              class="text-underline dark-link pointer">{{'SETTINGS_SECTION.ACCOUNT.CLEAR_RECORDINGS' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ngx-smart-modal #subscriptionSettingsModal identifier="subscriptionSettingsModal" [customClass]="'subscription-modal'"
  (onAnyCloseEvent)="handleSubscriptionCancel()">
  <rosie-subscription (onCancel)="handleSubscriptionCancel()" [page]="'account'"></rosie-subscription>
</ngx-smart-modal>
<ngx-smart-modal #clearRecordingModal identifier="clearRecordingModal">
  <h5 class="modal-title">
    {{'SETTINGS_SECTION.ACCOUNT.CLEAR_ALL_RECORDINGS' | translate}}
  </h5>
  <div class="modal-description" [innerHTML]="'SETTINGS_SECTION.ACCOUNT.CLEAR_RECORDINGS_DELETION' | translate">
  </div>
  <div class="modal-inline-buttons d-flex">
    <rosie-button (click)="clearRecordingModal.close()" class="d-block mr-1" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'btn-secondary btn-block'">
    </rosie-button>
    <rosie-button (click)="clearRecordingData()" class="d-block ml-1" [customClass]="'btn-danger btn-block'"
      [inProgress]="inProgress" [translationKey]="'COMMON.DELETE'">
    </rosie-button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #phoneModal identifier="phoneModal" (onAnyCloseEventFinished)="handleModalClose()">
  <h5 class="modal-title with-subtitle">
    {{'SETTINGS_SECTION.ACCOUNT.ADD_PHONE' | translate}}
  </h5>
  <div class="modal-subtitle">
    {{'SETTINGS_SECTION.ACCOUNT.ADD_PHONE_DESCRIPTION' | translate}}
  </div>
  <div class="modal-description">
    <rosie-phone [control]="phoneForm" class="flex-fill" [placeholderTranslationKey]="'(000) 000-0000'"
      [name]="'ACCOUNT_PHONE_NUMBER'"></rosie-phone>
  </div>
  <div class="modal-inline-buttons d-flex">
    <rosie-button (click)="phoneModal.close()" class="d-block mr-1" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'btn-secondary btn-block'">
    </rosie-button>
    <rosie-button (click)="savePhone()" [fullWidth]="true" [inProgress]="inProgress" [translationKey]="'COMMON.SAVE'"
      class="d-block w-100">
    </rosie-button>
  </div>
</ngx-smart-modal>