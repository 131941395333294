import { AfterContentInit, Component, ContentChildren, ElementRef, Input, OnDestroy, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { IconConfig } from '../../core/icon';

@Component({
  selector: 'rosie-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements AfterContentInit, OnDestroy {
  public hasContent: boolean;
  @ContentChildren('customContent') content: QueryList<ElementRef>;
  @Input() disabled = false;
  @Input() translationKey = 'COMMON.OK';
  @Input() customClass = 'btn-primary';
  @Input() customType = 'submit';
  @Input() inProgress = false;
  @Input() darkLoader = false;
  @Input() fullWidth = true;
  @Input() iconSuffix: string = '';
  @Input() iconSuffixConfig: IconConfig = { name: '', size: '20px', color: '#EAD7FD', strokeWidth: 2, disabledStateColor: '#A89FB3' };
  @Input() iconPrefix: string = '';
  private subscription: Subscription;

  constructor() {
  }

  get isDisabled(): boolean {
    return this.disabled === true;
  }

  get hasCustomClass(): boolean {
    return !!this.customClass;
  }

  get hasCustomType(): boolean {
    return !!this.customType;
  }

  ngAfterContentInit(): void {
    this.hasContent = (this.content.length > 0);
    this.subscription = this.content.changes.subscribe(() => this.hasContent = (this.content.length > 0));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}


