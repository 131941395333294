<div class="nav-sidebar-section-header align-items-center justify-content-between">
  <div class="header-navigator">
    <rosie-navigator (navClicked)="navClicked?.emit($event)" [iconClass]="navIcon" [translationKey]="title"
                     [url]="navUrl"></rosie-navigator>
  </div>
  <div [@slideIn]="triggerAnimation" [ngClass]="{ 'has-breadcrumbs': hasBreadcrumbs }" class="header-title">
    <div class="tag">
      {{ tagName | translate }}
    </div>
    <div class="breadcrumbs">
      <div *ngFor="let breadcrumb of breadcrumbs; trackBy: trackByLabel; let first=first" class="d-flex flex-row">
        <div [attr.title]="breadcrumb.label" [ngClass]="first ? 'title-text' : 'breadcrumb-text'">
          {{ breadcrumb.label | translate }}
        </div>
        <div *ngIf="breadcrumb.iconClass" [ngClass]="breadcrumb.iconClass" class="breadcrumb-icon"></div>
        <div *ngIf="!first" class="breadcrumb-separator"></div>
      </div>
    </div>
  </div>
  <div class="header-dropdown">
    <ng-content select="[header]"></ng-content>
  </div>

</div>

<div [ngClass]="customContentClass" class="nav-sidebar-section-main">
  <ng-content select="[main]"></ng-content>
</div>

<div class="nav-sidebar-section-footer">
  <ng-content select="[footer]"></ng-content>
</div>
