import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../core/page.service';
import { User, UserService } from '../core/user/user.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SubscriptionPage, SubscriptionService } from '../core/subscription/subscription.service';
import { CallsService } from '../core/calls/calls.service';
import { Subscription } from '../core/subscription/subscription';
import { BusinessService } from '../core/business/business.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AgentService } from '../core/agent/agent.service';
import { CallFinishedNotificationChannel } from '../core/agent/agent';


@Component({
  selector: 'rosie-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  activeSettingsSection: SettingsSection = SettingsSection.AccountInfo;
  inProgress = false;
  errorMessage = '';
  SettingsSection = SettingsSection;
  billingPortalUrl: SafeResourceUrl;
  notificationForm: FormGroup;
  phoneForm: FormControl;
  @ViewChild('subscriptionSettingsModal') subscriptionModal: NgxSmartModalComponent;
  @ViewChild('clearRecordingModal') clearRecordingsModal: NgxSmartModalComponent;
  @ViewChild('phoneModal') phoneModal: NgxSmartModalComponent;

  constructor(private user: UserService, private translate: TranslateService, private router: Router, private business: BusinessService, private agent: AgentService,
    private page: PageService, private route: ActivatedRoute, private subscription: SubscriptionService, private calls: CallsService, private fb: FormBuilder) {
    this.setBrowserTabTitle();
  }

  get userData(): User {
    return this.user.userData;
  }

  get settingsSections(): string[] {
    return objectPropertiesToArray<string>(SettingsSection);
  }

  get showAccountView(): boolean {
    return this.activeSettingsSection === SettingsSection.AccountInfo;
  }

  get hasActiveSubscription(): boolean {
    return this.user.hasActiveSubscription;
  }

  get subscriptionData(): Subscription {
    return this.userData.subscription;
  }

  get phoneNumber(): string {
    return this.agent.primary.business.information.additionalProperties.contactPhoneNumber;
  }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe((paramMap) => {
        const selectedSection = paramMap.get('section') as SettingsSection || SettingsSection.AccountInfo;
        if (this.activeSettingsSection !== selectedSection) {
          this.activeSettingsSection = selectedSection;
        }
      });

    this.createNotificationForm();
  }

  getTranslation(translationKey: string): string {
    return this.translate.instant(`SETTINGS_SECTION.TABS.${translationKey?.toUpperCase()}`);
  }

  handleSettingsSectionChange(section: SettingsSection): void {
    this.router.navigate(['/settings', { section }]);
  }

  redirectToBillingPortal(): void {
    window.open(this.userData.subscription?.billingUrl, '_blank');
  }

  subscribe() {
    this.subscriptionModal.open();
    this.subscription.initialize.next(SubscriptionPage.Account);
    this.subscription.created.subscribe(() => this.subscriptionModal.close());
  }

  clearRecordingData() {
    this.inProgress = true;
    this.calls.clearRecordings()
      .subscribe(() => {
        this.inProgress = false; this.clearRecordingsModal.close()
      }, () => this.inProgress = false);
  }

  handleSubscriptionCancel() {
    this.subscription.reset.next(SubscriptionPage.Account);
    this.subscriptionModal.close();
  }

  savePhone() {
    if (this.phoneForm.valid && !this.inProgress) {
      this.inProgress = true
      const businessInformationData: any = {
        additionalProperties: {
          contactPhoneNumber: this.phoneForm.value
        }
      };
      this.business.updateInformation(businessInformationData).subscribe(() => {
        this.phoneModal.close();
        this.agent.primary.business.information.additionalProperties.contactPhoneNumber = this.phoneForm.value;
        setTimeout(() => this.inProgress = false, 500);
      });
    }
  }

  handleModalClose() {
    if (!this.inProgress) {
      this.phoneForm.patchValue(this.agent.primary.business.information.additionalProperties.contactPhoneNumber);
    }
  }

  private setBrowserTabTitle(): void {
    this.page.initializePage(`Rosie - Settings`);
  }


  private createNotificationForm() {
    this.notificationForm = this.fb.group({
      emailNotificationEnabled: this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.Email),
      smsNotificationEnabled: this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.SMS),
    });

    this.notificationForm.valueChanges.subscribe((value) => {
      const callFinishedNotificationChannel = [];
      if (value.emailNotificationEnabled) {
        callFinishedNotificationChannel.push(CallFinishedNotificationChannel.Email);
      }
      if (value.smsNotificationEnabled) {
        callFinishedNotificationChannel.push(CallFinishedNotificationChannel.SMS);
      }
      this.agent.updateAgentConfiguration({ configuration: { callFinishedNotificationChannel } }).subscribe();
    });
    this.phoneForm = this.fb.control(this.agent.primary.business.information.additionalProperties.contactPhoneNumber, [Validators.required]);
  }
}

export enum SettingsSection {
  AccountInfo = 'accountInfo',
}
