import { Injectable } from '@angular/core';
import { DataStorage } from '../data/data-storage';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/rest-utils';
import { map, tap, forkJoin, Observable } from 'rxjs';
import { Agent } from '../agent/agent';
import { UserService } from '../user/user.service';
import { Business, BusinessInformation, BusinessPhoneNumber, BusinessPhoneNumberType } from './business';


@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  persistentStorage: DataStorage = DataStorage.persistent();

  constructor(private http: HttpClient, private user: UserService) {

  }

  get businessId(): string {
    return this.user.activeBusiness?.id;
  }

  get businessInformationId(): string {
    return this.user.activeBusiness?.information?.id;
  }

  updateInformation(businessInformationData: BusinessInformation) {
    return this.http.patch(`${getApiUrl()}business-information/${this.businessInformationId}`, businessInformationData, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  update(business: Business) {
    return this.http.put(`${getApiUrl()}businesses/${this.businessId}`, business, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  updateServices(services: BusinessService[]) {
    return this.http.post(`${getApiUrl()}businesses/${this.businessId}/services/batch`, { services }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  updatePhoneNumbers(phoneNumbers: BusinessPhoneNumber[]): Observable<any[]> {
    const deleteRequests = this.deletePhoneNumbers(phoneNumbers);
    const updateRequests = this.createPhoneNumbers(phoneNumbers);
    const createRequests = this.updateExistingPhoneNumbers(phoneNumbers);

    return forkJoin([...deleteRequests, ...updateRequests, ...createRequests]);
  }

  private deletePhoneNumbers(phoneNumbers: BusinessPhoneNumber[]): Observable<any>[] {
    return phoneNumbers
      .filter((phoneNumber) => phoneNumber.id && !phoneNumber.number)
      .map((phoneNumber) =>
        this.http.delete(`${getApiUrl()}businesses/${this.businessId}/phone-numbers/${phoneNumber.id}`, {
          headers: { 'X-Business-ID': this.user.activeBusiness?.id }
        }).pipe(map((res: any) => res.data))
      );
  }

  private updateExistingPhoneNumbers(phoneNumbers: BusinessPhoneNumber[]): Observable<any>[] {
    return phoneNumbers
      .filter((phoneNumber) => phoneNumber.id && phoneNumber.number)
      .map((phoneNumber) => {
        phoneNumber.number = phoneNumber?.number?.startsWith('+1') ? phoneNumber.number : `+1${phoneNumber.number}`;
        return this.http.put(`${getApiUrl()}businesses/${this.businessId}/phone-numbers/${phoneNumber.id}`, phoneNumber, {
          headers: { 'X-Business-ID': this.user.activeBusiness?.id }
        }).pipe(map((res: any) => res.data));
      });
  }

  private createPhoneNumbers(phoneNumbers: BusinessPhoneNumber[]): Observable<any>[] {
    return phoneNumbers
      .filter((phoneNumber) => !phoneNumber.id && phoneNumber.number)
      .map((phoneNumber) => {
        phoneNumber.number = phoneNumber?.number?.startsWith('+1') ? phoneNumber.number : `+1${phoneNumber.number}`;
        return this.http.post(`${getApiUrl()}businesses/${this.businessId}/phone-numbers`, phoneNumber, {
          headers: { 'X-Business-ID': this.user.activeBusiness?.id }
        }).pipe(map((res: any) => res.data));
      });
  }
}
