<div class="subscription-wrapper">
  <div class="d-flex justify-content-center mb-4">
    <img src="assets/images/rosie-icon-logo.svg">
  </div>
  <div class="heading text-center mt-4 mb-1"> {{'SUBSCRIPTION.HEADING' | translate}} </div>
  <div class="sub-heading text-center"> {{'SUBSCRIPTION.SUBHEADING' | translate}} </div>
  <div class="payment">
    <div class="plan-selection">
      <div class="sub-heading small mt-3 mx-5 mb-1"> {{'SUBSCRIPTION.SELECTED_PLAN' | translate | uppercase}}</div>
      <rosie-radio-group class="mx-5 d-block mb-4" [control]="selectedPlanControl" [dataHook]="'SUBSCRIPTION.PLAN'"
        [options]="getPlanOptions()"></rosie-radio-group>
    </div>
    <div class="sub-heading small mt-3 mx-5 mb-1"> {{'SUBSCRIPTION.PAYMENT_DETAILS' | translate | uppercase}}</div>
    <div class="rosie-pymt-container my-3 mx-5" [hidden]="!isInitializingPaymentMethod">
      <div [ngClass]="{'shimmer': isInitializingPaymentMethod}" class="rosie-pymt-loader-container">
        <div *ngFor="let i of [].constructor(3)" [shimmer]="isInitializingPaymentMethod" class="rosie-pymt-loader-item">
        </div>
      </div>
    </div>
    <form class="rosie-credit-card mt-3 mb-4 mx-5">
      <div [hidden]="isInitializingPaymentMethod">
        <div class=" form-group credit-card-number">
          <span #cardElement class="form-control rosie-form-control no-border" id="card-number"></span>
          <span [id]="getErrorElId('card-number')" class="rosie-error-message"></span>
          <label class="rosie-form-control-label" for="card-number">
            {{ 'SUBSCRIPTION.CARD_NUMBER_LABEL' | translate }}
          </label>
        </div>
        <div class="d-flex gap-1">
          <div class="form-group half-width">
            <span class="form-control rosie-form-control no-border" id="card-expiry"></span>
            <span [id]="getErrorElId('card-expiry')" class="rosie-error-message"></span>
            <label class="rosie-form-control-label" for="card-expiry">
              {{ 'SUBSCRIPTION.EXPIRY_DATE_LABEL' | translate }}
            </label>
          </div>
          <div class="form-group half-width">
            <span class="form-control rosie-form-control no-border" id="card-cvc"></span>
            <span [id]="getErrorElId('card-cvc')" class="rosie-error-message"></span>
            <label class="rosie-form-control-label" for="card-cvc">
              {{ 'SUBSCRIPTION.CVC_LABEL' | translate }}
            </label>
          </div>
        </div>
      </div>
      <rosie-alert [message]="paymentError" [type]="'error'" *ngIf="paymentError" class="mt-4 d-block"></rosie-alert>
      <rosie-button (click)="saveCreditCard()" [inProgress]="inProgress" [translationKey]="'SUBSCRIPTION.GET_STARTED'"
        class="d-block w-100 mt-3">
      </rosie-button>
    </form>
    <div class="sub-heading text-center mt-5 pt-2 mb-3" innerHTML="{{'SUBSCRIPTION.GET_STARTED_INFO' | translate:{value:
      licensedPlanPrice?.amount | rosieCurrency, minutes: selectedPlan?.minutesIncluded, pricePerMinute:
      meteredPlanPrice?.amount | rosieCurrency} }}"></div>
  </div>
  <div class="sub-heading text-center text-underline pointer" (click)="addPromotionCode()">
    {{'SUBSCRIPTION.HAVE_PROMOTION' |
    translate}} </div>
  <div class="coupon-code mt-2 mb-4" [ngClass]="{'invisible': !hasPromotionExpand}">
    <rosie-text [control]="cardholderForm.get('couponCode')"
      [placeholderTranslationKey]="'Promotion code'"></rosie-text>
  </div>
</div>