import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {objectPropertiesToArray} from '@rallycommerce/common/utils';
import {NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslationWidth} from '@angular/common';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const WEEKDAYS_SHORT = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

@Injectable()
export class NgbDatepickerI18nCustom extends NgbDatepickerI18n {
  getWeekdayShortName(weekday: number) {
    return WEEKDAYS_SHORT[weekday - 1];
  }

  getMonthShortName(month: number) {
    return MONTHS[month - 1];
  }

  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}-${this.getMonthFullName(date.month)}-${date.day}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return WEEKDAYS_SHORT[weekday - 1];
  }
}

@Component({
  selector: 'rosie-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nCustom}
  ]
})
export class DatePickerComponent implements OnInit {
  datePickerSelected: DatePickerOptions | string;
  datePickerOpened = false;

  dates: Dates = {
    translationWidth: TranslationWidth.Narrow,
    customDateSelected: '',
    hoveredDate: null
  };

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  @Input() initialDateInfo: DateInfo;
  @Input() inProgress = false;
  @Output() datePickerUpdated = new EventEmitter<DateInfo>();

  constructor(private calendar: NgbCalendar, private translate: TranslateService) {
    this.clearDatePickerSelection();
  }

  get datePickerOptions(): string[] {
    const datePickerOptionsArr = objectPropertiesToArray<string>(DatePickerOptions);
    return datePickerOptionsArr;
  }

  get isCustomDateOptionSelected(): boolean {
    return this.datePickerSelected === DatePickerOptions.Custom;
  }

  get isDatePickerOpened(): boolean {
    return this.datePickerOpened === true;
  }

  ngOnInit() {
    this.setInitialDate();
  }

  handleDatePickerToggle() {
    this.datePickerOpened = !this.datePickerOpened;
  }

  setDateRange() {
    const date: DateInfo = {
      dateRange: this.datePickerSelected,
      start: '',
      end: ''
    };
    this.datePickerUpdated.emit(date);
  }

  setDatePicker(option: DatePickerOptions) {
    this.datePickerSelected = option;
    this.dates.customDateSelected = null;
    // if custom date option has been selected - we need to clear any potential previous calendar dates selection
    this.isCustomDateOptionSelected ? this.clearDatePickerSelection() : this.setDateRange();
    this.datePickerOpened = option === DatePickerOptions.Custom;
  }

  getTranslation(translationKey: string): string {
    return this.translate.instant('DATE_PICKER.' + translationKey.toUpperCase());
  }

  isOptionCurrentlySelected(option: DatePickerOptions): boolean {
    return this.datePickerSelected === option;
  }

  setCustomDatePickerValue(fromDate: NgbDate, toDate: NgbDate): void {
    this.dates.customDateSelected = `${this.createDateDisplayFormat(fromDate)} - ${this.createDateDisplayFormat(toDate)}`;
  }

  clearDatePickerSelection() {
    this.fromDate = this.calendar.getToday();
    this.toDate = null;
    this.dates.customDateSelected = '';
    this.datePickerOpened = false;
  }

  setDatePickerSelection() {
    if (this.datePickerSelected === DatePickerOptions.Custom && !this.toDate) {
      this.toDate = this.fromDate;
    }
    const date: DateInfo = {
      dateRange: this.datePickerSelected,
      start: this.createDateApiFormat(this.fromDate),
      end: this.createDateApiFormat(this.toDate)
    };
    this.datePickerUpdated.emit(date);
    this.datePickerOpened = false;
  }

  // ngbDatePicker

  onDateSelection(date: NgbDate): void {
    if (this.calendarHasNoSelectedDates()) {
      this.fromDate = date;
    } else if (this.canBeEndDate(date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.setCustomDatePickerValue(this.fromDate, this.toDate);
  }

  isDateHovered(date: NgbDate): boolean {
    return this.fromDate && !this.toDate && this.dates.hoveredDate && date.after(this.fromDate) && date.before(this.dates.hoveredDate);
  }

  isDateInsideThisMonthRange(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isDateInRangeSelection(date: NgbDate): boolean {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isDateInsideThisMonthRange(date) || this.isDateHovered(date);
  }

  closeDatePicker() {
    this.datePickerOpened = false;
  }

  private setInitialDate() {
    this.datePickerSelected = this.initialDateInfo.dateRange;
    if (this.initialDateInfo.start) {
      const [startYear, startMonth, startDay] = this.initialDateInfo.start?.split('-').map(str => parseInt(str));
      const [endYear, endMonth, endDay] = this.initialDateInfo.end?.split('-').map(str => parseInt(str));
      this.fromDate = startYear ? new NgbDate(startYear, startMonth, startDay) : null;
      this.toDate = endYear ? new NgbDate(endYear, endMonth, endDay) : null;
      this.setDatePickerSelection();
    } else {
      this.setDateRange();
    }
  }

  private createDateApiFormat(date: NgbDate): string {
    return date ? `${date.year}-${date.month}-${date.day}` : '';
  }

  private createDateDisplayFormat(date: NgbDate): string {
    return date ? `${date?.month}/${date?.day}/${date?.year.toString().slice(2)}` : '';
  }

  private calendarHasNoSelectedDates(): boolean {
    return !this.fromDate && !this.toDate;
  }

  private canBeEndDate(date: NgbDate): boolean {
    return this.fromDate && !this.toDate && !date.before(this.fromDate);
  }

}

export enum DatePickerOptions {
  Today = 'today',
  Last7Days = 'last-7-days',
  Last30Days = 'last-30-days',
  Custom = 'custom'
}

export interface DateInfo {
  dateRange: string;
  start?: string;
  end?: string;
}

interface Dates {
  translationWidth: TranslationWidth,
  customDateSelected: string,
  hoveredDate: NgbDate | null
}
