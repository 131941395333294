import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, length: number): string {
    const biggestWord = 50;
    if (typeof value === 'undefined') {
      return value;
    }
    if (value.length <= length) {
      return value;
    }
    //.. truncate to about correct lenght
    let truncatedText = value.slice(0, length + biggestWord);

    //.. now nibble ends till correct length
    while (truncatedText.length > length) {
      const lastSpace = truncatedText.lastIndexOf(' ');
      if (lastSpace === -1) {
        truncatedText = '';
        break;
      }
      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?]$/, '');
    }
    return truncatedText;
  }
}
