import { SelectOption } from "../shared/select-input/select-input.component";

export const businessHours = (): string[] => {
  const intervals = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const period = hour < 12 ? 'AM' : 'PM';
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      const label = `${formattedHour}:${formattedMinutes}${period.toLowerCase()}`;
      intervals.push(label);
    }
  }
  intervals.push('Closed');
  return intervals;
};


export const businessDays = (): { day: string }[] => {
  return [{ day: 'Monday' }, { day: 'Tuesday' }, { day: 'Wednesday' }, { day: 'Thursday' }, { day: 'Friday' }, { day: 'Saturday' }, { day: 'Sunday' }];
};
export const timeZones: any[] = [
  { value: 'America/Los_Angeles', label: 'Pacific Time', timezone: '-0700' },
  { value: 'America/Denver', label: 'Mountain Time', timezone: '-0600' },
  { value: 'America/Chicago', label: 'Central Time', timezone: '-0500' },
  { value: 'America/New_York', label: 'Eastern Time', timezone: '-0400' },
  { value: 'America/Anchorage', label: 'Alaska Time', timezone: '-0800' },
  { value: 'America/Phoenix', label: 'Arizona/Yukon Time', timezone: '-0700' },
  { value: 'America/St_Johns', label: 'Newfoundland Time', timezone: '-0230' },
  { value: 'Pacific/Honolulu', label: 'Hawaii Time', timezone: '-1000' },
  { value: 'Australia/Sydney', label: 'Australia Eastern Standard Time', timezone: '+1000' },
  { value: 'Australia/Adelaide', label: 'Australian Central Standard Time', timezone: '+0930' },
  { value: 'Australia/Perth', label: 'Australian Western Standard Time', timezone: '+0800' },
  { value: 'Etc/UTC', label: 'UTC Time', timezone: '+0000' },
  { value: 'Europe/Berlin', label: 'Central European Time', timezone: '+0200' },
  { value: 'Europe/Vilnius', label: 'Eastern European Time', timezone: '+0300' },
  { value: 'Europe/London', label: 'UK/Ireland/Lisbon Time', timezone: '+0100' },
];
