import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'updatedAt'
})
export class UpdatedAtPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
    dayjs.extend(relativeTime);
    dayjs.extend(utc);
  }

  transform(value: string, messages: { sameDayMsg: string, moreThanADayMsg: string }, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    const timeAgo = dayjs.utc().diff(value, 'day');
    if (timeAgo === 0) {
      return this.translate.instant(messages.sameDayMsg) + dayjs(value).fromNow();
    }
    return this.translate.instant(messages.moreThanADayMsg) + dayjs(value).format('YYYY-MM-DD');
  }
}
