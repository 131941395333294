import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rosie-info-tile',
  templateUrl: './info-tile.component.html',
  styleUrls: ['./info-tile.component.scss']
})
export class InfoTileComponent {
  @Input() headingTranslationKey = '';
  @Input() descriptions: (string)[] = [];
  // iconName.fileType (e.g. test.svg) from `assets/icons` folder
  @Input() icon = 'warning-red-big.svg';

  constructor(private router: Router) {
  }

  // TODO remove when we get correct asset from Figma!
  get isDefaultIcon(): boolean {
    return this.icon === 'warning-red-big.svg';
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

}
