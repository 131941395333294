<div class="field-copy {{ customClass }}">
  <div [shimmer]="showShimmer" class="d-flex justify-content-between align-items-center">
    <div>
      <div class="field-copy-label">{{ labelKey | translate }}</div>
      <div class="field-copy-value">{{ value }}</div>
    </div>
    <rosie-button (click)="copyFieldValue()" [customClass]="'btn-copy btn-secondary btn-block uppercase'"
                  [translationKey]="hasCopiedValue() ? 'COMMON.COPIED' : 'COMMON.COPY'">
    </rosie-button>
  </div>
</div>
