<div (clickOutside)="closeDatePicker()" class="date-picker-wrapper position-relative">
  <div (click)="handleDatePickerToggle()" class="toggle">
    <img src='assets/images/calendar.svg'>
    <ng-container *ngIf="!dates.customDateSelected">{{ getTranslation(datePickerSelected) }}</ng-container>
    <ng-container *ngIf="dates.customDateSelected">{{ dates.customDateSelected }}</ng-container>
  </div>
  <div *ngIf="isDatePickerOpened" class="date-picker d-flex justify-content-between mt-2 position-absolute">
    <div class="predefined pt-2 pb-2">
      <div (click)="setDatePicker(option)" *ngFor="let option of datePickerOptions"
        [ngClass]="{'active': isOptionCurrentlySelected(option)}" class="option">
        {{ getTranslation(option) }}
      </div>
    </div>

    <div *ngIf="isCustomDateOptionSelected" class="calendar">
      <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [dayTemplate]="t" [displayMonths]="2"
        [startDate]="fromDate" [weekdays]="dates.translationWidth">
      </ngb-datepicker>
      <ng-template #t let-currentMonth="currentMonth" let-date let-focused="focused">
        <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
          [class.faded]="isDateHovered(date) || isDateInsideThisMonthRange(date)" [class.focused]="focused"
          [class.outside]="date.month !== currentMonth" [class.range]="isDateInRangeSelection(date)" class="custom-day">
          {{ date.day }}
        </span>
      </ng-template>

      <div class="actions d-flex justify-content-end">
        <rosie-button (click)="clearDatePickerSelection()" [customClass]="'btn-slimmer btn-sm btn-light'"
          [translationKey]="'COMMON.CANCEL'" class="cancel mr-2">
        </rosie-button>
        <rosie-button (click)="setDatePickerSelection()" [customClass]="'btn-slimmer btn-sm btn-horizontal btn-success'"
          [inProgress]="inProgress" [translationKey]="'COMMON.APPLY'" class="submit">
        </rosie-button>
      </div>
    </div>

  </div>
</div>