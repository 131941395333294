import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {InternalAnalyticsService} from './internal-analytics.service';
import {BusinessService} from './business/business.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private title: Title, private internalAnalytics: InternalAnalyticsService, private business: BusinessService) {

  }

  initializePage(pageTitle: string) {
    this.title.setTitle(pageTitle);
    this.internalAnalytics.trackPage(pageTitle);
  }
}
