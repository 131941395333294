import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rosie-dropdown',
  template: `
    <div ngbDropdown container="dropdown-wrapper" dropdownClass="dropdown-wrapper" [placement]="dropdownTogglePlacement"
         class="dropdown-wrapper" [ngClass]="{'disabled': isDropdownDisabled}"
         (openChange)="handleToggle($event)">
      <div [ngClass]="isTextDropdownSelector ? 'options-text' : 'options-icon'" class="with-hover rosie-pointer" ngbDropdownToggle
           id="dropdownOptions" [dataHook]="'dropdown-toggle'">
        <ng-container *ngIf="isTextDropdownSelector">
          {{ selectorText }}
        </ng-container>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownOptions" [ngClass]="dropdownStyle">
        <button *ngFor="let item of actionItems" ngbDropdownItem [dataHook]="'dropdown-option-' + item.name"
                [ngClass]="(item.class || '') + ' ' + item.id" (click)="handleActionSelect(item.id)" itemId="{{ item.id }}">
          <div class="rosie-d-flex">
            <div class="{{item.id}} {{item.class}} d-flex justify-content-between">
              {{ item.name | translate }}
              <span *ngIf="item.icon" class="mr-4"><img [src]="getIconUrl(item.icon)"/></span>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div *ngIf="isDropdownDisabled" [ngbTooltip]="isDropdownDisabled ? disabledText : ''" tooltipClass="tooltip-wrapper dropdown-tooltip"
         class="overflow-tooltip" placement="top-right"></div>
  `,
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Output() actionSelected = new EventEmitter<string>();
  @Output() dropdownToggled = new EventEmitter<boolean>();

  @Input() config: DropdownConfig;

  constructor(private translate: TranslateService) {
  }

  get actionItems(): ActionItem[] {
    return this.config?.items;
  }

  get dropdownStyle(): string {
    return this.config?.style.dropdown;
  }

  get isTextDropdownSelector(): boolean {
    return !!this.config?.selectorText;
  }

  get selectorText(): string {
    return this.translate.instant(this.config?.selectorText);
  }

  get isDropdownDisabled(): boolean {
    return this.config?.disabled;
  }

  get disabledText(): string {
    return this.translate.instant(this.config?.disabledText);
  }

  get dropdownTogglePlacement(): DropdownPlacement {
    return this.config?.placement || DropdownPlacement.BottomRight;
  }

  handleActionSelect(id: string) {
    this.actionSelected.next(id);
  }

  handleToggle(toggled: boolean) {
    this.dropdownToggled.next(toggled);
  }

  getIconUrl(icon: string): string {
    return `assets/images/${icon.toLowerCase()}.svg`;
  }

}

export interface DropdownConfig {
  style?: { dropdown: string };
  items?: ActionItem[];
  selectorText?: string;
  disabled?: boolean;
  disabledText?: string;
  placement?: DropdownPlacement;
}

export function defaultDropdown(): DropdownConfig {
  const defaultDropdownConfig = {
    style: { dropdown: 'dropdown-wrapper' },
    items: []
  };
  return defaultDropdownConfig;
}

export interface ActionItem {
  name: string;
  id: string;
  icon?: string;
  class?: string;
}

export enum ItemAction {
  Preview = 'preview',
  Edit = 'edit',
  Delete = 'delete',
  Remove = 'remove',
  Update = 'update',
  Add = 'add',
  Replace = 'replace',
  Settings = 'settings',
  Manage = 'manage'
}

export enum DropdownPlacement {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right'
}
