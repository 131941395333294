import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { generateUniqueAdminId } from '../../core/helpers/utils';

@Component({
  selector: 'rosie-number-input',
  template: `
    <ng-container *ngIf="control">
      <div class="input-group position-relative" [ngClass]="customClass">
        <label *ngIf="labelTranslationKey" [for]="id" class="rosie-form-control-label label-{{quantitySelectorStyle}}">
          {{ labelTranslationKey | translate }}
        </label>
        <ng-container *ngIf="isQuantitySelectorStyleFloating; else defaultQuantitySelector">
          <div class="d-flex w-100 justify-content-between number-input-wrapper-{{quantitySelectorStyle}}">
            <input type="number" placeholder="{{placeholderTranslationKey | translate}}"
                   class="rosie-form-control form-control" [formControl]="control" [id]="id">
            <button (click)="updateQty(false)" type="button" class="btn" [disabled]="isMinQuantity" [dataHook]="'rosie-number-input-minus'">
              <i class="minus-icon"></i>
            </button>
            <button (click)="updateQty()" type="button" class="btn" [disabled]="isMaxQuantity" [dataHook]="'rosie-number-input-plus'">
              <i class="plus-icon"></i>
            </button>
          </div>
        </ng-container>
        <ng-template #defaultQuantitySelector>
          <div class="input-group-prepend">
            <button (click)="updateQty(false)" type="button" class="btn btn-outline-secondary minus" [disabled]="isMinQuantity"
                    [dataHook]="'rosie-number-input-minus'">
              <fa-icon [icon]="faMinus"></fa-icon>
            </button>
          </div>
          <input type="number" class="form-control" [formControl]="control" [id]="id">
          <div class="input-group-append">
            <button (click)="updateQty()" type="button" class="btn btn-outline-secondary plus" [disabled]="isMaxQuantity"
                    [dataHook]="'rosie-number-input-plus'">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
  `,
  styleUrls: ['./number.input.component.scss']
})
export class NumberInputComponent implements OnInit {
  faPlus = faPlus;
  faMinus = faMinus;

  @Input() id = generateUniqueAdminId();
  @Input() placeholderTranslationKey = '';
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() min: number;
  @Input() max: number;
  @Input() labelTranslationKey: string;
  @Input() quantitySelectorStyle: QuantitySelectorStyle = QuantitySelectorStyle.Default;
  @Input() customClass = '';

  constructor() {
  }

  get isMinQuantity(): boolean {
    return this.min ? this.control.value <= this.min : false;
  }

  get isMaxQuantity(): boolean {
    return this.max ? this.control.value >= this.max : false;
  }

  get isQuantitySelectorStyleFloating(): boolean {
    return this.quantitySelectorStyle === QuantitySelectorStyle.Floating;
  }

  ngOnInit() {
    this.configureControlBehavior();
  }

  updateQty(add = true) {
    this.control.setValue(+this.control.value + (add ? 1 : -1));
  }

  private configureControlBehavior(): void {
    if (!this.control.value) {
      this.control.setValue(1, { emitEvent: false });
    }
    this.control.valueChanges
      .subscribe((value) => {
        if (!isNaN(this.min) && value < this.min) {
          this.control.setValue(this.min);
        }

        if (!isNaN(this.max) && value > this.max) {
          this.control.setValue(this.max);
        }
      });
  }
}

export enum QuantitySelectorStyle {
  Default = 'default',
  Floating = 'floating'
}
