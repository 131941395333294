<div class="apps-wrapper" [ngClass]="{'mobile': isMobileVersion}">
  <div class="app-heading">{{'SIDEBAR.INTEGRATIONS' | translate}}</div>
  <div *ngIf="!selectedApp else appDetails;">
    <!-- <div class="d-flex">
      <div (click)="setActiveAppTypeFilter(appType)" *ngFor="let appType of appTypes;"
        [dataHook]="'apps-type-' + appType" [ngClass]="{'active': appType === activeType}" class="app-type-filter-item">
        <span>{{ getAppTypeTranslationKey(appType) | translate }}</span>
      </div>
    </div> -->
    <div class="d-flex app-list justify-content-center justify-content-md-start gap-4">
      <div (click)="seeApp(app.key)" *ngFor="let app of appsByActiveType" [dataHook]="'apps-name-' + app.name"
        [hidden]="isAppHidden(app)" class="app-item">
        <div class="logo-wrapper position-relative">
          <img [src]="getLogo(app)">
        </div>
        <div class="d-flex justify-content-between align-items-center heading-line">
          <div class="title">{{ app.name }}</div>
          <div *ngIf="app.isInstalled" class="installed d-flex align-items-center justify-content-center">
            <i class="install-icon"></i>
          </div>
          <div *ngIf="app.isPending" class="pending d-flex align-items-center justify-content-center">
            <i class="pending-icon"></i>
          </div>
        </div>
        <div [innerHTML]="app.summary" class="description"></div>
      </div>
    </div>
  </div>
</div>
<ng-template #appDetails>
  <div (click)="seeApp('')" [dataHook]="'APPS.BACK'" class="d-flex align-items-center pointer back">
    <div class="icon"><span class="chevron dark-left sm d-block"></span></div>
    <span>{{ 'APPS.BACK' | translate }}</span>
  </div>
  <rosie-app [app]="selectedApp"></rosie-app>
</ng-template>